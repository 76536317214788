import React from 'react'
import { useNavigate } from 'react-router-dom'
import { changeTotalItem, userInfoDefault, dropdown, notificationChange } from "../../redux/UserInfoSlice";
import { useDispatch } from 'react-redux';
import { removeToken } from '../CodeEasier';

function NoData({ message, height, button, dukandar }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const path = window.location.pathname

  function change() {
    const backdrop = document.getElementsByClassName('modal-backdrop')
    backdrop.length > 0 ? backdrop[0].remove() : <></>
    dispatch(dropdown(false))
    removeToken()
    dispatch(userInfoDefault())
    dispatch(changeTotalItem('set0'))
    dispatch(notificationChange())
    navigate(`/user/createUser/?redirect=${path}`)
  }


  return (
    <div className='container d-flex' style={{ justifyContent: 'center', alignItems: 'center', minHeight: height ? height : '75vh', flexDirection: 'column', gap: '20px' }}>
      <img src={`${process.env.PUBLIC_URL}/images/notfound.png`} alt='No data found for your request' className='img-fluid' style={{ maxWidth: '250px' }} />
      <h5 className='h5 text-center'>{message} </h5>
      {button ? <>
        Log out with this account and
        <button className='h6 secondry-button ps-3 pe-3 m-0 ' style={{ width: 'fit-content', transform: 'translateY(-10px)' }} onClick={() => change()}>Create Grahak account</button>
      </> : dukandar ? <div >Contact Us - 6260823922 </div> : <> </>}
    </div>
  )
}

export default NoData

// {button ? <button className='h6 secondry-button ps-3 pe-3 m-0' style={{width : 'fit-content'}} onClick={()=>change()}>Create account</button> : <> </>}
