import React , {useState, useCallback }from 'react'
import { useDispatch } from 'react-redux'
import { addDukanNotification } from '../redux/DukandarSlice'

function AddDukanNotification({disable, setError}) {
    const [data, setData] = useState({
        'title': '',
        'notification': ''
    })
    const dispatch = useDispatch()

    function change(e) {
        setData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }


    const add = useCallback(async () => {
        const title_trimed = data.title.trim();
        const notification_trimed = data.notification.trim();
        if (title_trimed === '') {
            setError('Please write title!');
        } else if (title_trimed.length > 50) {
            setError('Title word limit = 50 words !');
        } else if (notification_trimed === '') {
            setError('Please write notification!');
        } else if (notification_trimed.length > 400) {
            setError('Notification word limit = 400 words !');
        } else {
            dispatch(addDukanNotification(data));
            setData({
                'title': '',
                'notification': ''
            });
        }
    }, [data, dispatch, setError]);


    return (
        <>
            <div className='addnotification mt-3 row'>
                <div className='col-md-8'>
                    <label className='h5' style={{ color: 'red' }}>Title : </label>
                    <span style={{ fontSize: '12px' }}> (words : {data.title.length})</span>
                    <input className='h5' type='text' name='title' value={data.title} onChange={(e) => change(e)} placeholder='Title' />
                </div>
                <div className='col-md-12 mt-3'>
                    <label className='h5' style={{ color: 'red' }}>Notification : </label>
                    <span style={{ fontSize: '12px' }}> (words : {data.notification.length})</span>
                    <textarea rows = {6} className='h6' type='text' name='notification' value={data.notification} onChange={(e) => change(e)} placeholder='Notification' />
                </div>
            </div>
            <button className='h6 primary-button ps-3 pe-3 mt-4 pt-1 pb-1 ms-0' style={{ width: 'fit-content' }} onClick={() => add()} disabled={disable}>Add Notification</button>
        </>
    )
}

export default AddDukanNotification