import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ErrorUp, Sloading, SuccessUp, catchError, url, getDate, getToken, errorName, openLink, changeNumberFormat, nextOnEnter, scrollFocus, PhoneIcon, CrossIcon, RightIcon, OpenLinkIcon, ToolboxIcon, ShopIcon, LinkIcon, WhatsappIcon, BilldeskIcon, EditIcon, CustomerIcon } from "../base/index";
import { LinkCustomer, SingleOrderItem } from './index'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateDukanOrder } from "../redux/DukandarSlice";
import HomeIcon from "../base/svg/HomeIcon";
import InfoIcon from "../base/svg/InfoIcon";
import UserIcon from "../base/svg/UserIcon";


const SingleOrder = React.memo(({ item, setPerror, is_dukandar = false, totalShopping = -1, totalRecived = -1, setCustomerDetailNOrder = null, setTotalShopping = null, setTotalRecived = null }) => {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [orderItems, setOrderItems] = useState(null)
    const [msg, setMsg] = useState(null)
    const [orderTotalDetail, setOrderTotalDetail] = useState(false)
    const [showRemaining, setShowRemaining] = useState(false)
    // const [edit, setEdit] = useState(false)
    const [toolbox, setToolbox] = useState(false)
    const [isPaid, setIsPaid] = useState(false)
    const [isDeliverd, setIsDeliverd] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [additionalTotal, setAdditionalTotal] = useState(0)
    const [additional, setAdditional] = useState(null)
    const [discount, setDiscount] = useState(0)
    const [amountRecived, setAmountRecived] = useState(0)
    const [number, setNumber] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const refrence = {
        itemRef: useRef(null),
        additonalTotalRef: useRef(null),
        amountRef: useRef(null),
        numberRef: useRef(null),
        discountRef: useRef(null),
        additionalRef: useRef(null),
    }


    function setDefault() {
        console.log('hereee', item)
        setIsPaid(item.is_paid)
        setIsDeliverd(item.is_deliverd)
        setTotalPrice(item.total_price)
        setAdditionalTotal(item.additional_total)
        setAmountRecived(item.amount_recived)
        setNumber(item.order_number)
        setAdditional(item.additional)
        setDiscount(item.discount)
    }

    async function getOrderItems() {
        setIsLoading(true)
        setMsg(null)
        console.log('getinng item for order id ----', item.id)
        try {
            const newUrl = is_dukandar ? `${url}/api/dukandar/order/${item.id}/` : `${url}/api/order/${item.id}/`
            const token = getToken()
            const request = await axios.get(newUrl, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            request.data && request.data.error_message ? request.data.error_message === errorName.internalError ? setPerror(request.data.error_message) : setMsg(request.data.error_message) : setOrderItems(request.data.data)
            setIsLoading(false)
        }
        catch (error) {
            setIsLoading(false)
            setPerror(catchError(error).error_message)
        }
    }

    async function updateOrder() {
        if (totalPrice === '' || Number(totalPrice) < 0) {
            setError('Enter Item Total !')
            scrollFocus(refrence.itemRef)
        }
        else if (additionalTotal === '' || Number(additionalTotal) < 0) {
            setError('Enter Additional Total !')
            scrollFocus(refrence.additonalTotalRef)
        }
        else if (amountRecived === '' || Number(amountRecived) < 0) {
            setError('Enter Amount Recived !')
            scrollFocus(refrence.amountRef)
        }
        else if (number === '' || number.toString().length !== 10 || number <= 0) {
            setError('Enter 10 digit number !')
            scrollFocus(refrence.numberRef)
        }
        else if (discount === '' || Number(discount) < 0) {
            setError('Enter Discount !')
            scrollFocus(refrence.discountRef)
        }
        else {
            try {
                const token = getToken()
                const request = await axios.put(`${url}/api/dukandar/order/${item.id}/`, {
                    'is_paid': isPaid,
                    'is_deliverd': isDeliverd,
                    'total_price': totalPrice,
                    'additional_total': additionalTotal,
                    'amount_recived': amountRecived,
                    'order_number': number,
                    'discount': discount,
                    'additional': additional,
                    'total_shopping': totalShopping,
                    'total_recived' : totalRecived
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                console.log(request)
                if (request.data && request.data.error_message) {
                    if (request.data.error_message === errorName.internalError) {
                        setPerror(request.data.error_message)
                    }
                    setError(request.data.error_message)
                }
                else {
                    dispatch(updateDukanOrder({ 'page': 'singleOrder', 'updatedOrder': request.data.data }))
                    setTotalShopping(request.data.total_shopping)
                    setTotalRecived(request.data.total_recived)
                    setSuccess('Order updated !')
                }
            }
            catch (error) {
                setPerror(catchError(error).error_message)
            }
        }
    }

    function get() {
        orderItems === null ? getOrderItems() : <></>
    }

    function sendMsgOnWhatsapp() {
        const deliverd = !item.deliverd_at ? `Not deliverd (चिंता मत करिये हम आपका सामान कुछ ही देर में आपके घर पहुँचा देंगे)` : getDate(item.deliverd_at)
        const totalToPay = Number(item.total_price) + Number(item.additional_total) - Number(item.discount) - Number(item.amount_recived)
        const msg = `Namaste🙏 *${item.order_username}*,
Thank you for shopping😊 with *${item.dukan_name}*.
 
*-------Your Bill Details-------* 
Bill ID:                        *MKB-${item.id}* 
Total Items :             *${item.total_item}*
Item Total :               *${changeNumberFormat(item.total_price)} /-*
Additional Total :     *${changeNumberFormat(item.additional_total)} /-*
Discount :                  *${changeNumberFormat(item.discount)} /-*
Amount Recieved :  *${changeNumberFormat(item.amount_recived)} /-*
Total to pay :            *${changeNumberFormat(totalToPay)} /-*
Order On :      *${getDate(item.created_at)}*
Deliverd on :   *${deliverd}*
Delivery Address : *H no.${item.order_house_no}, S/W no. ${item.order_street_no}, ${item.order_colony}, ${item.order_city}*

Bill : *${item.order_pdf}*

*MahajanKirana, Apne nagar ki kirana..*
     मंगाओ हर महीने का राशन, *Online*😎🥳
अब अपने ही *नगर की किराना दुकान से*🎉🤩

*mahajankirana.in*`

        const phoneNumber = item.order_number
        const url = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? `https://wa.me/${phoneNumber}?text=${encodeURIComponent(msg)}` : `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(msg)}`
        window.open(url, '_blank')
    }

    useEffect(() => {
        if (item) {
            console.log('itemm----------------', item)
            setDefault()
        }
    }, [item])

    return (
        <>
            {
                item && (<div className='singleorder' style={{ position: 'relative', overflow: 'visible' }} data-aos="zoom-in-down">
                    <h1 className="h5 hindi text-center" style={{ textDecoration: 'underline' }}>श्री गणेशाय नमः</h1>
                    <div className='orderdetail row g-2 mt-0'>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                            <h6 className="h6"><span>ID :</span> MKB-{item.id}</h6>
                        </div>
                        <div className="col-6 d-block d-md-none ">
                            <h6 className="h6">Total Items : {changeNumberFormat(item.total_item)}</h6>
                        </div>
                        {is_dukandar ? <>
                            <div className="col-12 col-sm-9 col-md-7 col-lg-5">
                                <h6 className="h6 d-flex gap-2"><CustomerIcon style={{ width: '19px' }} /> : {item.customer_detail ? <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/dukandar/customers/${item.customer_detail.split(',')[0].split('-')[1]}/detailNorders/`)}>{item.customer_detail}</span> : <CrossIcon style={{ fill: 'red', width: '16px' }} />}</h6>
                            </div>
                            <div className="col-12 col-sm-3 col-md-2 col-lg-2">
                                <h6 className="h6 d-flex gap-2"><UserIcon style={{ width: '14px' }} /> :<span style={!item.created_by_user ? { textDecoration: 'underline' } : { textDecoration: 'none' }}> {item.user_detail ? item.user_detail.split(' ')[0] : <CrossIcon style={{ fill: 'red', width: '16px' }} />}</span></h6>
                            </div> </> : <div className="col-12 col-sm-9 col-md-9 col-lg-7 d-flex gap-1"><ShopIcon /> : {item.dukan_name}</div>
                        }
                        <div className="d-none d-md-block col-6 col-sm-6 col-md-3 col-lg-2">
                            <h6 className="h6">Total Items : {changeNumberFormat(item.total_item)}</h6>
                        </div>
                        <div className="col-7 col-sm-6 col-md-5 col-lg-3">
                            <h6 className="h6">Order on : {getDate(item.created_at)}</h6>
                        </div>
                        <div className="col-8 col-sm-6 col-md-4 col-lg-3 o-2">
                            <h6 className="h6">Deliverd on : {isDeliverd ? item.deliverd_at ? getDate(item.deliverd_at) : getDate() : 'Not deliverd'}</h6>
                        </div>
                        <div className="col-sm-10 col-md-9 col-lg-4 o-sm-1 o-4 ">
                            <h6 className="h6">Name : {item.order_username}</h6>
                        </div>
                        <div className="col-5 col-sm-6 col-md-3 col-lg-2 o-1">
                            <h6 className="h6 d-flex gap-2" style={{ alignItems: 'center' }}><PhoneIcon /> : {number}</h6>
                        </div>
                        <div className="col-12 col-md-10 col-lg-10 o-sm-3 o-5 ">
                            <h6 className="h6 d-flex gap-2" style={{ alignItems: 'center' }}><HomeIcon /> : {item.order_house_no !== 0 ? `H no. ${item.order_house_no},` : <></>} {item.order_street_no !== 0 ? `W/S no. ${item.order_street_no}` : <></>} {item.order_colony}, {item.order_city}</h6>
                        </div>
                        <div className="col-4 col-sm-2 col-md-2 col-lg-2 o-sm-2 o-3 ">
                            <h6 className="h6"> List : {item.order_list ? <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => openLink(item.order_list)}> show </span> : ' No'} </h6>
                        </div>
                        <div className="col-5 col-sm-3 col-md-3 col-lg-2 h6 o-6 o-sm-4 d-none d-sm-block">
                            Additional : {additional === '' ? 'No' : 'Yes'}
                        </div>
                        <div className="h6 col-8 pe-sm-0 ps-sm-0 col-sm-9 col-md-7 col-lg-7 o-sm-5 o-7 d-flex flex-column flex-sm-row justify-content-sm-evenly gap-2 gap-sm-0" style={{ overflow: 'visible' }}>
                            <div style={{ position: 'relative', display: 'flex', gap: '5px', overflow: 'visible' }}>Bill Total : {changeNumberFormat(Number(additionalTotal) + Number(totalPrice) - Number(discount))}/- <span onClick={() => setOrderTotalDetail(!orderTotalDetail)}><InfoIcon className='infoIcon' /></span>
                                <div className="billTotalDetail" style={orderTotalDetail ? { opacity: 1 } : { opacity: 0 }}>
                                    <h6 className="h6">Item Total : <span>+ {changeNumberFormat(totalPrice)} /-</span></h6>
                                    <h6 className="h6">Additional Total : <span>+ {changeNumberFormat(additionalTotal)} /-</span></h6>
                                    <h6 className="h6">Discount : <span style={{ color: 'red' }}>- {changeNumberFormat(discount)} /-</span></h6>
                                </div>
                            </div>
                            <div style={{ position: 'relative', display: 'flex', gap: '5px', overflow: 'visible' }}>Amount Recived : {changeNumberFormat(Number(amountRecived))}/- <span onClick={() => setShowRemaining(!showRemaining)}><InfoIcon className='infoIcon' /></span>
                                <div className="remainingDetail" style={showRemaining ? { opacity: 1, zIndex: '10' } : { opacity: 0, zIndex: '-1' }}>
                                    <h6 className="h6">Remaining : <span>{changeNumberFormat(Number(totalPrice) + Number(additionalTotal) - Number(discount) - Number(amountRecived))} /-</span></h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 col-sm-8 col-md-2 col-lg-3 pt-1 pb-1 o-sm-6 o-8 offset-sm-2 offset-md-0">
                            <button className="primary-button h6 pt-1 pb-1" data-bs-toggle="modal" data-bs-target={`#exampleModal${item.id}`} onClick={() => get()}>Items</button>
                        </div>
                    </div>

                    <h6 className="h6 d-flex" style={{ position: 'absolute', top: '12px', alignItems: 'center', gap: '5px' }}> Paid : {isPaid ? <RightIcon style={{ width: '14px', fill: 'blue' }} /> : <CrossIcon style={{ width: '14px', fill: 'red' }} />} </h6>

                    <div className="toolbox" style={{ gap: toolbox ? '15px' : '0px' }}>
                        <div className={toolbox ? "toolboxdata active" : "toolboxdata"}>
                            {is_dukandar ? <span data-bs-toggle="modal" data-bs-target={`#linkcustomer${item.id}`}><LinkIcon style={{ fill: 'orange', stroke: 'orange', strokeWidth: '18px', width: '22px', cursor: 'pointer' }} /> </span> : <></>}

                            {item.order_pdf ? <div className="d-flex" style={{ gap: '15px' }}>
                                <span style={{ display: 'flex' }} onClick={() => openLink(item.order_pdf)}><OpenLinkIcon style={{ fill: 'orangered', cursor: 'pointer', width: '19px' }} /></span>
                                {is_dukandar ? <span onClick={() => sendMsgOnWhatsapp()}><WhatsappIcon style={{ fill: 'green', cursor: 'pointer', stroke: 'green', strokeWidth: '8px' }} /> </span> : <></>}
                            </div> : <></>}
                            {is_dukandar ? <div style={{ display: 'flex', gap: '15px' }}>
                                <span onClick={() => navigate(`/dukandar/billDesk/?bill_id=${item.id}`)}><BilldeskIcon style={{ fill: 'blue', cursor: 'pointer', width: '15.5px' }} /> </span>
                                <span data-bs-toggle="modal" data-bs-target={`#exampleModaledit${item.id}`}><EditIcon style={{ fill: 'red', cursor: 'pointer', width: '21px' }} /></span>
                            </div> : <></>}
                        </div>
                        <span className="d-flex overflow-hidden" onClick={() => setToolbox(!toolbox)}><ToolboxIcon style={{ cursor: 'pointer', width: '21px' }} className={toolbox ? "fa-solid fa-toolbox  active" : "fa-solid fa-toolbox "} /></span>
                    </div>

                    {/* order items */}
                    <div className="modal fade p-0" id={`exampleModal${item.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable ms-0 me-0 ms-sm-auto me-sm-auto" >
                            <div className="modal-content" style={{ borderRadius: '10px' }} >
                                <div className="modal-header">
                                    <h5 className="modal-title h5" id="exampleModalLabel">Order Items</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {isLoading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}> <Sloading color={'red'} width={'50px'} height={'fit-content'} style={{ margin: 'auto' }} /></div> :
                                        orderItems !== null ?
                                            <>
                                                {
                                                    orderItems.map((item, key) => {
                                                        return (
                                                            <SingleOrderItem item={item} key={key} setPerror={setPerror} />
                                                        )
                                                    })
                                                }
                                            </> : <h1 className="h6 p-3">{msg === null ? 'Oops! No item in this order.' : msg} </h1>}

                                    <div className="m-3 h6"><span className="h5" style={{ textDecoration: 'underline', color: 'blue' }}>Additional Products</span> : {additional === '' ? ' No Products ' : additional} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* for dukandar to  update the  bill */}

                    <div class="modal fade" id={`exampleModaledit${item.id}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Order </h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='orderedit' style={{ scrollbarWidth: 'none', width: '98%', margin: 'auto', padding: '10px' }}>
                                        <div className="row ">
                                            <div className='col-3 col-sm-3 d-flex h6 ' style={{ alignItems: 'center', gap: '5px', color: 'gray' }}> Paid : <input className='h6' style={{ width: 'fit-content' }} onChange={(e) => setIsPaid(!isPaid)} checked={isPaid} type='checkbox' /> </div>
                                            <div className='col-3 col-sm-3 d-flex h6 ps-0 pe-0 ps-sm-auto' style={{ alignItems: 'center', gap: '5px', color: 'gray' }}> Deliverd : <input className='h6' style={{ width: 'fit-content' }} onChange={(e) => setIsDeliverd(!isDeliverd)} checked={isDeliverd} type='checkbox' /> </div>
                                            <div className='col-6 col-sm-6 d-flex h6 mt-1' style={{ alignItems: 'center', gap: '10px', color: 'gray' }}>Items Total : <input ref={refrence.itemRef} onKeyDown={(e) => nextOnEnter(e, refrence.additonalTotalRef)} className='h6' style={{ flex: 1, padding: '2px' }} onChange={(e) => setTotalPrice(e.target.value)} value={totalPrice} type='number' /> </div>
                                            <div className='col-sm-6 d-flex h6 mt-1 pe-0' style={{ alignItems: 'center', gap: '10px', color: 'gray' }}> Additional Total : <input ref={refrence.additonalTotalRef} onKeyDown={(e) => nextOnEnter(e, refrence.amountRef)} className='h6' style={{ flex: 1, padding: '2px' }} onChange={(e) => setAdditionalTotal(e.target.value)} value={additionalTotal} type='number' /> </div>
                                            <div className='col-6 col-sm-6 d-flex h6 mt-1 mb-1' style={{ alignItems: 'center', gap: '10px', color: 'gray' }}> Discount : <input ref={refrence.discountRef} onKeyDown={(e) => nextOnEnter(e, refrence.additionalRef)} className='h6' style={{ flex: 1, padding: '2px' }} onChange={(e) => setDiscount(e.target.value)} value={discount} type='number' /> </div>
                                            <div className='col-6 col-sm-6 d-flex h6 mt-1' style={{ alignItems: 'center', gap: '10px', color: 'gray' }}>Recived : <input ref={refrence.amountRef} onKeyDown={(e) => nextOnEnter(e, refrence.numberRef)} className='h6' style={{ flex: 1, padding: '2px' }} onChange={(e) => setAmountRecived(e.target.value)} value={amountRecived} type='number' /> </div>
                                            <div className='col-sm-6 d-flex h6 mt-1 mb-1 ps-sm-0' style={{ alignItems: 'center', gap: '10px', color: 'gray' }}> Number : <input ref={refrence.numberRef} onKeyDown={(e) => nextOnEnter(e, refrence.discountRef)} className='h6' style={{ flex: 1, padding: '2px' }} onChange={(e) => setNumber(e.target.value)} value={number} type='number' /> </div>
                                            <div style={{ color: 'gray' }}>Additional : <textarea ref={refrence.additionalRef} onKeyDown={(e) => nextOnEnter(e, refrence.itemRef)} rows={3} className='h6' style={{ padding: '2px' }} onChange={(e) => setAdditional(e.target.value)} value={additional} /></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer pt-0 pb-2" style={{ float: 'right' }}>
                                    <button onClick={() => setDefault()} className="me-3 secondry-button h6 ps-4 pe-4" style={{ width: 'fit-content' }}>Refresh</button>
                                    <button onClick={() => updateOrder()} className="m-0 secondry-button h6 ps-4 pe-4" style={{ width: 'fit-content' }} data-bs-dismiss="modal">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* link with customer */}
                    <LinkCustomer linkedcustomer={item.customer_detail} order_id={item.id} setCustomerDetailNOrder={setCustomerDetailNOrder} />



                    {
                        error !== null ? <ErrorUp removeMessage={setError} message={'sdfsdf'} /> : success !== null ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
                    }
                </div >)
            }
        </>
    )
})

export default SingleOrder;




