import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BilldeskIcon, CrossIcon, EditIcon, PhoneIcon } from '../base'

const SingleCustomer = React.memo(({ customer }) => {
  console.log(customer)
  const navigate = useNavigate()
  return (
    <div className='singlecustomer mb-4 overflow-visible'>
      <div className='row'>
        <div className='col-sm-6 col-md-8 col-lg-8 h6 mt-2 d-flex gap-2'>
          <label>User Id </label> : {customer.user === null ? <CrossIcon style={{ width: '15px', fill: 'red' }} /> : `MKU-${customer.user}`}
        </div>
        <div className='col-sm-6 col-md-4 col-lg-4 h6 mt-2 d-flex gap-2'>
          <label>Customer Id </label> : MKC-{customer.id}
        </div>
        <div className='col-sm-12 col-md-8 col-lg-8 h6 mt-2 d-flex gap-2'>
          <label>Name</label>: {customer.name}
        </div>
        <div className='col-sm-12 col-md-4 col-lg-4 h6 mt-2  d-flex gap-2 '>
          <label><PhoneIcon style={{width : '18px'}}/> </label>: {customer.number === 0 ? '' : customer.number}
        </div>
        <div className='col-12 h6 mt-2 d-flex gap-2'>
          <label style={{flex : 'none'}}>Address</label> : House No. {customer.house_no}, Street No. {customer.street_no}, {customer.colony}, {customer.city}
        </div>
      </div>

      <span onClick={() => navigate(`/dukandar/customers/${customer.id}/detailNorders/`)}><BilldeskIcon style={{ fill: 'blue', width: '18px', position: 'absolute', top: '-13px', right: '15px', cursor: 'pointer', background : 'white'  }} /></span>
      <span onClick={() => navigate(`/dukandar/customers/?action=update&customerToupdate=${customer.id}`)}><EditIcon style={{ width : '22px', 'position': 'absolute', 'fill': 'red', 'top': '-13px', 'right': '45px', background : 'white' }} /></span>
    </div>
  )
})

export default SingleCustomer