import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDukanAllCategory, getCategoryProduct, setCategoryName, setSearchedProduct, getSearchedProduct, dukandarDefault } from "../../redux/DukandarSlice";
import { dropdown } from "../../redux/UserInfoSlice";
import { Helmet } from "react-helmet";
import { SingleProduct, AddDukanProduct, UpdateDukanProduct } from "../../components/index";
import { Loading, NoData, NotAuthorized, NoConnection, message, errorName, NotFound404 } from "../../base/index";
import { useLocation, useNavigate } from "react-router-dom";


function DukanProduct() {
  const { user, is_dukandar, userInfoLoading } = useSelector((state) => state.userInfo)
  const { dukanAllCategory, categoryProduct, dukandarLoading, dukandarError, dukandarErrorType, categoryName } = useSelector((state) => state.dukandar)
  const [operation, setOperation] = useState('products')
  const [display, setDisplay] = useState('none')
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const path = window.location.pathname
  const location = useLocation()
  const cat = new URLSearchParams(location.search).get('category')
  const ptu = new URLSearchParams(location.search).get('productToupdate')
  const action = new URLSearchParams(location.search).get('action')
  const navigate = useNavigate()
  const [data, setData] = useState({
    'category': '',
    'name': '',
    'mrp': '',
    'price': '',
    'image': null,
    'tag': '',
    'offer': '',
    'loose': false,
  })

  function api(category) {
    setSearch('')
    dispatch(setCategoryName(category.replace(/\-+/g, ' ')))
    dispatch(getCategoryProduct(encodeURIComponent(category.replace(/\s+/g, "-"))))
  }

  useEffect(() => {
    dukanAllCategory.length === 0 ? dispatch(getDukanAllCategory()) : <></>
    dispatch(dropdown(false))

    return () => dispatch(setSearchedProduct(null))
  }, [dispatch])


  useEffect(() => {
    cat ? api(cat) : <></>
  }, [cat])

  // useEffect(() => {
  //   setLoading(false)
  //   // console.log('accit', action)
  //   action === 'add' ? setOperation('add') : action === 'update' ? setOperation('update') : setOperation('products')
  // }, [action])

  useEffect(() => {
    action === 'update' && ptu ? dispatch(getSearchedProduct({ 'item': encodeURIComponent(ptu), 'billDesk': false })) : <></>
  }, [action, ptu])

  useEffect(() => {
    search.length < 1 ? setDisplay('none') : setDisplay('flex')
    setCategory(
      dukanAllCategory.filter((item) => {
        return item.toLowerCase().includes(search.toLowerCase())
      })
    )
  }, [search])

  console.log('proudct')
  return (
    <section className="dukancategory">
      <Helmet>
        <title>Dukan Product</title>
      </Helmet>

      <div style={{ overflowX: 'visible' }}>
        {
          dukandarLoading || userInfoLoading ? <Loading /> :
            dukandarError === errorName["404Error"] ? <NotFound404 /> :
              dukandarError === errorName.internalError ? <NoConnection /> :
                dukandarError === errorName.networkError ? <NoConnection network='true' /> :
                  user === null || dukandarError === errorName.authenticationError ? <NotAuthorized path={encodeURIComponent(`${path}${location.search}`)} /> :
                    is_dukandar === false || dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                      <>
                        <div className='addupdateoperation'>
                          <div onClick={() => navigate(`/dukandar/product/?action=products`)} className={action !== 'add' && action !== 'update' ? 'active h6' : 'h6'}>Product</div>
                          <div onClick={() => navigate(`/dukandar/product/?action=add`)} className={action === 'add' ? 'active h6' : 'h6'}>Add</div>
                          <div onClick={() => navigate(`/dukandar/product/?action=update`)} className={action === 'update' ? 'active h6' : 'h6'}>Update</div>
                        </div>

                        {/* {action !== 'add' && action !== 'update' && operation === 'products' ?
                      <div className='my-container'>
                        <div className='searchlist mt-4 mb-4'>
                          <div className='searchlistinput'>
                            <input className="h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search category here." />
                            <i className="fa-solid fa-xmark h6" onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '9px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
                          </div>
                          {
                            category.length > 0 ?
                              <div className='searchlistdiv' style={{ display: display }}>
                                <div style={{ width: '100%' }}>
                                  {
                                    category && category.map((item, key) => {
                                      return (
                                        <div className="searchlistvalue" key={key} onClick={() => api(item)}>
                                          <h1 className="h6" key={key}>{item} </h1>
                                          <hr style={{ margin: '0px' }} />
                                        </div>
                                      )
                                    })}
                                </div>
                              </div> : <></>
                          }
                        </div>
                        {
                          categoryProduct !== null ?
                            <div >
                              <div className='heading h4 mb-2 mt-2'>{categoryName} Proudcts</div>
                              <div className='row'>
                                {
                                  categoryProduct.map((item, key) => {
                                    return (
                                      <SingleProduct item={item} key={key} dukandar={true} />
                                    )
                                  })
                                }
                              </div>
                              <h4 />
                            </div> : <NoData message={`Oops! No products found for ${categoryName} category.`} />
                        }</div> :
                      operation === 'add' ?
                        <AddDukanProduct data={data} setData={setData} /> :
                        operation === 'update' ?
                          <UpdateDukanProduct /> : <> </>
                    }  */}

                        {
                          action === 'add' ?
                            <AddDukanProduct data={data} setData={setData} /> :
                            action === 'update' ?
                              <UpdateDukanProduct /> :
                              <div className='my-container'>
                                <div className='searchlist mt-4 mb-4'>
                                  <div className='searchlistinput'>
                                    <input className="h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search category here." />
                                    <i className="fa-solid fa-xmark h6" onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '9px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
                                  </div>
                                  {
                                    category.length > 0 ?
                                      <div className='searchlistdiv' style={{ display: display }}>
                                        <div style={{ width: '100%' }}>
                                          {
                                            category && category.map((item, key) => {
                                              return (
                                                <div className="searchlistvalue" key={key} onClick={() => api(item)}>
                                                  <h1 className="h6" key={key}>{item} </h1>
                                                  <hr style={{ margin: '0px' }} />
                                                </div>
                                              )
                                            })}
                                        </div>
                                      </div> : <></>
                                  }
                                </div>
                                {
                                  categoryProduct !== null ?
                                    <div >
                                      <div className='heading h4 mb-2 mt-2'>{categoryName} Proudcts</div>
                                      <div className='row'>
                                        {
                                          categoryProduct.map((item, key) => {
                                            return (
                                              <SingleProduct item={item} key={key} dukandar={true} />
                                            )
                                          })
                                        }
                                      </div>
                                      <h4 />
                                    </div> : <NoData message={`Oops! No products found for ${categoryName} category.`} />
                                }</div>
                        }
                      </>
        }
      </div>
    </section>
  )
}

export default DukanProduct