import React, { useEffect, useRef, useState } from 'react';
import { cartDefault, getCartItem, orderComplete } from '../../redux/CartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, NoConnection, NotAuthorized, NoData, SuccessUp, ErrorUp, errorName, message, scrollFocus, nextOnEnter, NotFound404, url, socketSend } from '../../base/index'
import { CartItem } from '../../components/index';
import { useNavigate } from 'react-router-dom';
import { changeTotalItem, dropdown } from '../../redux/UserInfoSlice';
import { Helmet } from 'react-helmet';
import { getLatestOrder } from '../../redux/OrderSlice';


function Cart() {
    const { cartLoading, cartError, cartSuccess, cart, total_price, address, cartErrorType } = useSelector((state) => state.cart)
    const orders = useSelector((state) => state.order.orders)
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const total_item = useSelector((state) => state.userInfo.total_item)
    const default_dukan_name = useSelector((state) => state.userInfo.default_dukan_name)
    const default_dukan_id = useSelector((state) => state.userInfo.default_dukan_id)
    const [error, setError] = useState(null)
    const [checkbox, setCheckBox] = useState(false)
    const [homeDelivery, setHomeDelivery] = useState(true)
    const [success, setSuccess] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const path = window.location.pathname
    const [data, setData] = useState({
        'order_username': '',
        'order_house_no': '',
        'order_street_no': '',
        'order_colony': '',
        'order_city': '',
        'order_number': '',
        'order_pin': '451224',
        'additional': '',
        'order_list': null
    })
    const refrence = {
        nameRef: useRef(null),
        houseRef: useRef(null),
        streetRef: useRef(null),
        areaRef: useRef(null),
        cityRef: useRef(null),
        phoneRef: useRef(null),
        pinRef: useRef(null),
        additionalRef: useRef(null),
        listRef: useRef(null),
        chekcboxRef: useRef(null)
    }
    console.log(data, cart)

    function change(e) {
        const { name, value, files } = e.target
        setData((prev) => {
            return {
                ...prev,
                [name]: files ? files.length > 0 ? files[0] : null : value
            }
        })
        // if (e.target.name === 'order_list') {
        //     setData((prev) => {
        //         return {
        //             ...prev,
        //             [e.target.name]: e.target.files[0],
        //         };
        //     });
        // } else {
        //     setData((prev) => {
        //         return {
        //             ...prev,
        //             [e.target.name]: e.target.value,
        //         };
        //     });
        // }
    }
    console.log(total_item)

    function placeOrder() {
        console.log(data)
        if (total_item === 0 && data.additional.trim() === '' && data.order_list === null) {
            setError('Please write additional item / upload list / add products')
            scrollFocus(refrence.additionalRef)
        }
        else if (homeDelivery && data.order_username.trim() === '') {
            setError('Enter your name!')
            scrollFocus(refrence.nameRef)
        }
        else if (homeDelivery && (data.order_house_no < 0 || data.order_house_no === '')) {
            setError('Enter house number!')
            scrollFocus(refrence.houseRef)
        }
        else if (homeDelivery && (data.order_street_no < 0 || data.order_street_no === '')) {
            setError('Enter street number!')
            scrollFocus(refrence.streetRef)
        }
        else if (homeDelivery && data.order_colony.trim() === '') {
            setError('Enter Area / Calony!')
            scrollFocus(refrence.areaRef)
        }
        else if (homeDelivery && data.order_city.trim() === '') {
            setError('Enter city!')
            scrollFocus(refrence.cityRef)
        }
        else if (homeDelivery && data.order_number.toString().length !== 10) {
            setError('Enter 10 digit whatsapp number!')
            scrollFocus(refrence.phoneRef)
        }
        // else if (homeDelivery && data.order_pin.toString().length !== 6) {
        //     setError('Enter 6 digits pin!')
        //     scrollFocus(refrence.pinRef)
        // }
        else if (!checkbox) {
            setError('Please select checkbox !')
            console.log(refrence.chekcboxRef)
            refrence.chekcboxRef.current.style.color = 'red'
            const timeout = setTimeout(() => {
                refrence && refrence.chekcboxRef && refrence.chekcboxRef.current ? refrence.chekcboxRef.current.style.color = 'rgb(114, 114, 114)' : <></>
            }, [800])

            return () => clearTimeout(timeout)
        }
        else {
            if (data.order_list === null) {
                if (homeDelivery) {
                    dispatch(orderComplete({ 'data': { ...data, 'home_delivery': homeDelivery } }))
                }
                else {
                    const { order_username, order_house_no, order_street_no, order_colony, order_city, order_number, order_pin, ...newData } = data
                    console.log(newData)
                    dispatch(orderComplete({ 'data': { ...newData, 'home_delivery': homeDelivery } }))
                }
            }
            else {
                if (homeDelivery) {
                    const formData = new FormData();
                    const newData = { ...data, 'home_delivery': homeDelivery }
                    {
                        for (const key in newData) {
                            formData.append(key, newData[key])
                        }
                    }
                    dispatch(orderComplete({ 'data': formData }))
                }
                else {
                    const { order_username, order_house_no, order_street_no, order_colony, order_city, order_number, order_pin, ...newData } = { ...data, 'home_delivery': homeDelivery }
                    const formData = new FormData();
                    {
                        for (const key in data) {
                            formData.append(key, newData[key])
                        }
                    }
                    dispatch(orderComplete({ 'data': formData }))
                }
            }
        }
    }


    useEffect(() => {
        dispatch(dropdown(false))
        dispatch(getCartItem())
    }, [dispatch])

    useEffect(() => {
        if (address !== null) {
            setData({
                'order_username': address?.order_username,
                'order_pin': address?.order_pin,
                'order_number': address?.order_number === 0 ? '' : address?.order_number,
                'order_city': address?.order_city,
                'order_colony': address?.order_colony,
                'order_house_no': address?.order_house_no === 0 ? '' : address?.order_house_no,
                'order_street_no': address?.order_street_no === 0 ? '' : address?.order_street_no,
                'additional': '',
                'order_list': null
            })
        }
    }, [address])

    useEffect(() => {
        console.log(cartError, cartSuccess)
        if (cartSuccess === 'Thank you for shopping!') {
            setSuccess(cartSuccess)
            const timeout = setTimeout(() => {
                dispatch(changeTotalItem('set0'))
                dispatch(cartDefault())
                orders !== null && dispatch(getLatestOrder()) 
                // const socket = new WebSocket(`${url}/ws/dukandarConsumer/`)
                // socket.onopen =()=>{
                //     socketSend(socket, {'action' : 'NEW_ORDER', 'dukan_id' : default_dukan_id})
                // }
                navigate('/myOrders/')
            }, [1000])
            return () => clearTimeout(timeout)
        }
        else if (cartError && cartError.includes('image')) {
            setError('Upload only image / photo of your list |')
            dispatch(cartDefault())
        }
        else if (cartError && cartErrorType === 'normal') {
            setError(cartError)
            dispatch(cartDefault())
        }
    }, [cartError, cartSuccess])

    console.log(cartError, error)
    return (
        <section>
            <Helmet>
                <title>Shopping Cart {`${total_item}`}</title>
            </Helmet>
            {
                cartLoading || userInfoLoading ? <Loading /> :
                    cartError === errorName['404Error'] ? <NotFound404 /> :
                        cartError === errorName.internalError ? <NoConnection /> :
                            cartError === errorName.networkError ? <NoConnection network='true' /> :
                                cartError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                    cartError === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                        <>
                                            {
                                                cart !== null ?
                                                    <div className='container cartitem'>
                                                        <h6 className='h6 text-center mt-1'>" {default_dukan_name} "</h6>
                                                        <h1 className='h4 heading mt-1'>Your Cart Items!</h1>
                                                        <div className='row'>
                                                            {
                                                                cart.map((item, key) => {
                                                                    return (
                                                                        <CartItem item={item} key={key} />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <h1 className='h4'>Total : <span style={{ color: 'red' }}> {total_price}</span> Rs</h1>
                                                        <br /> <hr /> <br />
                                                    </div>
                                                    : <NoData message={'Oops! No item in the cart, Shop now to add.'} />
                                            }

                                            <div className='d-flex gap-2 m-auto' style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <label>Want Home Delivery ? </label>
                                                <input style={{ width: 'fit-content' }} type='checkbox' checked={homeDelivery} onChange={(e) => setHomeDelivery(!homeDelivery)} />
                                            </div>


                                            <div className='container deliveryaddress' style={{ overflow: 'hidden' }}>
                                                <div className='address' style={homeDelivery ? { 'opacity': 1, height: '100%', transition: 'all .5s ease-in-out' } : { 'opacity': 0, height: '0px', transition: 'all .5s ease-in-out' }}>
                                                    <div className='h4 heading'>Delivery Address</div>

                                                    <div className='row ps-2 pe-2 ps-sm-0 pe-sm-0' data-aos="flip-right">
                                                        <div className='col-sm-12 col-md-8 col-lg-6 mt-2'>
                                                            <label className='h5'>Full name : </label>
                                                            <input ref={refrence.nameRef} onKeyDown={(e) => nextOnEnter(e, refrence.houseRef)} className='h6' type='text' name='order_username' onChange={(e) => change(e)} value={data.order_username} placeholder={address?.order_username ? address?.order_username : 'Kamal Ramnarayan Mahajan'} /> <br />
                                                        </div>
                                                        <div className='col-sm-6 col-md-4 col-lg-6 mt-2'>
                                                            <label className='h5'>House no. : </label>
                                                            <input ref={refrence.houseRef} onKeyDown={(e) => nextOnEnter(e, refrence.streetRef)} className='h6' type='number' name='order_house_no' onChange={(e) => change(e)} value={data.order_house_no} placeholder={address?.order_house_no ? address.order_house_no : '39'} /><br />
                                                        </div>
                                                        <div className='col-sm-6 col-md-5 col-lg-6 mt-2'>
                                                            <label className='h5'>Street no. / Ward No. : </label>
                                                            <input ref={refrence.streetRef} onKeyDown={(e) => nextOnEnter(e, refrence.areaRef)} className='h6' type='number' name='order_street_no' onChange={(e) => change(e)} value={data.order_street_no} placeholder={address?.order_street_no ? address.order_street_no : '3'} /><br />
                                                        </div>
                                                        <div className='col-sm-12 col-md-7 col-lg-6 mt-2'>
                                                            <label className='h5'>Colony / Mohalla / Area : </label>
                                                            <input ref={refrence.areaRef} onKeyDown={(e) => nextOnEnter(e, refrence.cityRef)} className='h6' type='text' name='order_colony' onChange={(e) => change(e)} value={data.order_colony} placeholder={address?.order_colony ? address?.order_colony : 'Bhagat Sing Marg'} /> <br />
                                                        </div>
                                                        <div className='col-sm-6 mt-2'>
                                                            <label className='h5'>City : </label>
                                                            <input ref={refrence.cityRef} onKeyDown={(e) => nextOnEnter(e, refrence.phoneRef)} className='h6' type='text' name='order_city' onChange={(e) => change(e)} value={data.order_city} placeholder={address?.order_city ? address?.order_city : 'ex - Maheshwar'} /><br />
                                                        </div>
                                                        <div className='col-sm-6 mt-2'>
                                                            <label className='h5'>WhatsApp number : </label>
                                                            <input ref={refrence.phoneRef} onKeyDown={(e) => nextOnEnter(e, refrence.additionalRef)} className='h6' type='number' name='order_number' onChange={(e) => change(e)} value={data.order_number} placeholder={address?.order_number ? address?.order_number : 'ex - 1234567890'} /><br />
                                                        </div>
                                                        {/* <div className='col-sm-6 mt-2'>
                                                <label className='h5'>Pin : </label>
                                                <input ref = {refrence.pinRef} onKeyDown={(e)=>nextOnEnter(e, refrence.additionalRef)} className='h6' type='number' name='order_pin' onChange={(e) => change(e)} value={data.order_pin} placeholder={address?.order_pin ? address?.order_pin : 'ex - 451224'} /><br />
                                            </div> */}
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-8 mt-2'>
                                                        <label className='h5'>Additional products</label>
                                                        <textarea ref={refrence.additionalRef} onKeyDown={(e) => nextOnEnter(e, refrence.nameRef)} className='h6' rows={10} name='additional' onChange={(e) => change(e)} value={data.additional} placeholder='ex - 250g besan, 30 rupay ki haldi, 100g khula ghee, 50g kaju'></textarea> <br />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='h6 mb-2'>Have list ? Just upload here.</div>
                                                        <input type="file" id="imageInput" className='h6' name='order_list' onChange={(e) => change(e)} style={{ borderBottom: 'none' }} />
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                        <label ref={refrence.chekcboxRef}>Are you sure? place your order</label>
                                                        <input style={{ width: 'fit-content' }} type='checkbox' checked={checkbox} onChange={(e) => setCheckBox(!checkbox)} />
                                                    </div>
                                                    <div className='col-12 pt-1 pb-1 mt-3'>
                                                        <button className='col-12 h6 secondry-button third-button' style={{ width: 'fit-content', padding: '6.5px 30px' }} onClick={() => placeOrder()}>Place order</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                                            }
                                        </>
            }
        </section>
    )
}

export default Cart;