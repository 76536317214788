import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fallbackImg, getToken, url } from '../base'
import axios from 'axios'
import { setTopCategory } from '../redux/UserInfoSlice'
import { useNavigate } from 'react-router-dom'
import LazyImage from './LazyImage'

function TopCategory() {
    const top_category = useSelector((state) => state.userInfo.top_category)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    async function getTopCategory() {
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/topCategory/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(request.data)
            if (request.data && !request.data.error_message) {
                dispatch(setTopCategory(request.data))
            }
        }
        catch (error) {

        }
    }
    useEffect(() => {
        !top_category && getTopCategory()
    }, [top_category])

    
    return (
        <>
            {top_category &&
                <><div className='h4 heading'>Start with Top Category !</div>
                    <div className='topCategory container'>
                        {
                            top_category.map((item, index) => {
                                return (
                                    <div className='singleTopCategory' key={index}>
                                        <LazyImage src={item.image} className='img-fluid' alt='category'/>
                                        {/* <img src={item.image} /> */}
                                        <h6 className='truncate2' onClick={()=>navigate(`/category/${item.name.replace(/\s+/g, "-")}/products/`)} style={{ marginBottom: '0', fontSize: '12px' }}>{item.name}</h6>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }
        </>
    )
}

export default TopCategory