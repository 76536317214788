import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { errorName, handleRequest, catchError, url, getToken } from '../base/index'
// creating intial state

const intialState = {
    dukandarError: null,
    dukandarErrorType: null,
    dukandarLoading: false,
    dukandarSuccess: null,

    dukanCategory: null,
    dukanCategoryPage: 1,
    dukanCategoryFirstTime: true,
    searchedCategory: null,
    dukanAllCategory: [],
    dukanCategoryLastPage: false,

    categoryName: '',
    categoryProduct: null,
    searchedProduct: null,

    dukanCustomer: null,
    dukanCustomerPage: 1,
    dukanCustomerFirstTime: true,
    dukanCustomerLastPage: false,
    searchedCustomer: null,

    dukanUser: null,
    dukanUserPage: 1,
    dukanUserFirstTime: true,
    dukanUserLastPage: false,

    dukanProfile: null,
    dukanNotification: null,

    dukanOrder: null,
    dukanOrderPage: 1,
    dukanOrderFirstTime: true,
    dukanOrderLastPage: false,
    orderFilter: {
        'is_paid': 'all',
        'is_deliverd': 'all'
    },

    dukanBillDeskOrder: null,
    dukanBillDeskOrderPage: 1,
    dukanBillDeskOrderLastPage: false
}

const baseUrl = `${url}/api/dukandar`

const getDukanUser = createAsyncThunk('getDukanUser', async (page, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getDukanUser/`, {
            params: {
                page: page
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanCustomer = createAsyncThunk('getDukanCustomer', async ({ page }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/dukanCustomer/`, {
            params: {
                page: page
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const addDukanCustomer = createAsyncThunk('addDukanCustomer', async (data, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.post(`${baseUrl}/dukanCustomer/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getSearchedCustomer = createAsyncThunk('getSearchedCustomer', async (id, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getSearchedCustomer/?id=${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const updateDukanCustomer = createAsyncThunk('updateDukanCustomer', async (data, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.put(`${baseUrl}/dukanCustomer/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})



const getDukanAllCategory = createAsyncThunk('getDukanAllCategory', async (_, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/dukanCategory/`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                only_name: 'yes'
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanCategory = createAsyncThunk('getDukanCategory', async (page, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/dukanCategory/`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                page: page
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const addDukanCategory = createAsyncThunk('addDukanCategory', async ({ data }, { rejectWithValue }) => {
    console.log('calingg dukan categeroy functions')
    try {
        const token = getToken()
        const request = await axios.post(`${baseUrl}/dukanCategory/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getSearchedCategory = createAsyncThunk('getSearchedCategory', async (slug, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getSearchedCategory/?category_slug=${slug}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const updateDukanCategory = createAsyncThunk('updateDukanCategory', async ({ category, data }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.put(`${baseUrl}/dukanCategory/?category_slug=${category}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getCategoryProduct = createAsyncThunk('getCategoryProduct', async (category, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/dukanProduct/?category=${category}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const addDukanProduct = createAsyncThunk('addDukanProduct', async ({ data }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.post(`${baseUrl}/dukanProduct/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getSearchedProduct = createAsyncThunk('getSearchedProduct', async ({ item, billDesk }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getSearchedProduct/?product=${item}&billDesk=${billDesk}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const updateDukanProduct = createAsyncThunk('updateDukanProduct', async ({ product, data }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.put(`${baseUrl}/dukanProduct/?product=${product}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanOrder = createAsyncThunk('getDukanOrder', async ({ page, filters }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/order/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: filters
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanNotification = createAsyncThunk('getDukanNotification', async (_, { rejectWithValue }) => {
    try {
        const token = getToken();
        const request = await axios.get(`${baseUrl}/notification/`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })

        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const addDukanNotification = createAsyncThunk('addDukanNotification', async (data, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.post(`${url}/api/dukandar/notification/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const deleteDukanNotification = createAsyncThunk('deleteDukanNotification', async (id, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.delete(`${url}/api/dukandar/notification/?notification=${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanProfile = createAsyncThunk('getDukanProfile', async (_, { rejectWithValue }) => {
    try {
        const token = getToken();
        const request = await axios.get(`${baseUrl}/dukanProfile/`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })

        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const udpateDukanProfile = createAsyncThunk('udpateDukanProfile', async ({ data }, { rejectWithValue }) => {
    try {
        const token = getToken();
        const request = await axios.put(`${baseUrl}/dukanProfile/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })

        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const saveOrderPdf = createAsyncThunk('saveOrderPdf', async ({ bill_id, data }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.post(`${url}/api/dukandar/order/saveOrderPdf/?bill_id=${bill_id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

// -------------------------------------



const dukandarSlice = createSlice({
    name: 'dukandar',
    initialState: intialState,
    reducers: {
        dukandarDefault(state, action) {
            state.dukandarError = null
            state.dukandarSuccess = null
        }, // set error and success to null
        setDukandarError(state, action) {
            state.dukandarError = action.payload
            state.dukandarErrorType = 'exception'
        }, // set dukandar error = action.payload
        incDukanCustomerPage(state, action) {
            state.dukanCustomerPage = state.dukanCustomerPage + 1
        }, // increment the page number for dukan customer
        incDukanCategoryPage(state, action) {
            state.dukanCategoryPage = state.dukanCategoryPage + 1
        }, // increment the page number for dukan category
        incDukanUserPage(state, action) {
            state.dukanUserPage = state.dukanUserPage + 1
        }, // increment the page number for dukan user
        setCategoryName(state, action) {
            state.categoryName = action.payload
        }, // set the category name to show
        incDukanOrderPage(state, action) {
            action.payload === 0 ?
                state.dukanOrderFirstTime = true :
                state.dukanOrderPage = state.dukanOrderPage + 1
        }, // increment the page number for dukan order
        updateDukanOrder(state, action) {
            console.log(action.payload)
            state.dukanOrder = state.dukanOrder ? state.dukanOrder.map((item, index) => {
                if (item.id === action.payload.updatedOrder.id) {
                        return { ...action.payload.updatedOrder }
                }
                else { return { ...item } }
            }) : null

        }, // when dukandar update the bill total additional recived etc
        addNewOrder(state, action) {
            state.dukanOrder = state.dukanOrder ? [action.payload, ...state.dukanOrder] : state.dukanOrder
        }, // create new order for a customer at customer page
        updateLinkCustomerInDuaknOrder(state, action) {
            const { id, customer, customer_detail } = action.payload
            state.dukanOrder = state.dukanOrder ? state.dukanOrder.map((item, index) => {
                return item.id === action.payload.data.id ? { ...action.payload.data } : { ...item }
            }) : null
        }, // when dukandar link dislink the order with customer
        changeFilter(state, action) {
            if (action.payload.type === 'order') {
                state.orderFilter = {
                    ...state.orderFilter,
                    [action.payload.name]: action.payload.value
                }
            }
        }, // change the filters at dukan orders page
        applyFilter(state, action) {
            if (action.payload.type === 'order') {
                state.dukanOrderFirstTime = true
                state.dukanOrderLastPage = false
                state.dukanOrder = null
                state.dukandarLoading = true
                state.dukanOrderPage = 1
            }
        }, // apply filters at dukandar order page
        setSearchedCategory(state, action) {
            state.searchedCategory = action.payload
        }, // set the searched category  to null or action.payload
        setSearchedProduct(state, action) {
            state.searchedProduct = action.payload
        }, // set the searched product to null or action.payload
        setSearchedCustomer(state, action) {
            console.log('called', action.payload)
            state.searchedCustomer = action.payload
        }, // set the searched customer to null or action.payload
        actionWhenCustomerlinkDislinkWithUser(state, action) {
            const {id, user, username} = action.payload
            console.log(state.dukanCustomer)
            state.dukanCustomer = state.dukanCustomer && state.dukanCustomer.map((item, index)=>{
                if(item.id === id) {
                    return {...item, user : user, username : username}
                }
                else {
                    return {...item}
                }
            })
            // state.dukanOrder = state.dukanOrder && state.dukanOrder.map((item, index) => {})
        }, // change the dukan customer user field 
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDukanUser.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.dukandarErrorType = null
            })
            .addCase(getDukanUser.fulfilled, (state, action) => {
                console.log(action.payload)
                state.dukandarLoading = false
                state.dukanUser = state.dukanUser === null ? action.payload.data : [...state.dukanUser, ...action.payload.data]
                state.dukanUserFirstTime = false
            })
            .addCase(getDukanUser.rejected, (state, action) => {
                console.log(action.payload)
                state.dukandarLoading = false
                action.payload.error_message === errorName.invalidPage ?
                    state.dukanUserLastPage = true :
                    state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) // getDukanUser

            .addCase(getDukanCustomer.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
                state.dukandarSuccess = null
            })
            .addCase(getDukanCustomer.fulfilled, (state, action) => {
                console.log(action.payload)
                state.dukandarLoading = false
                state.dukanCustomer = state.dukanCustomer === null ? action.payload.data : [...state.dukanCustomer, ...action.payload.data]
                state.dukanCustomerFirstTime = false
            })
            .addCase(getDukanCustomer.rejected, (state, action) => {
                console.log(action.payload)
                state.dukandarLoading = false
                action.payload.error_message === errorName.invalidPage ?
                    state.dukanCustomerLastPage = true :
                    state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) // getDukanCustomer

            .addCase(addDukanCustomer.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
                state.dukandarSuccess = null
            })
            .addCase(addDukanCustomer.fulfilled, (state, action) => {
                state.dukandarSuccess = action.payload.success_message
                state.dukandarLoading = false
                state.dukanCustomer = state.dukanCustomer ? [action.payload.data, ...state.dukanCustomer] : state.dukanCustomer
            })
            .addCase(addDukanCustomer.rejected, (state, action) => {
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
                state.dukandarLoading = false
            }) // addDukanCustomer

            .addCase(getSearchedCustomer.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
                state.dukandarSuccess = null
            })
            .addCase(getSearchedCustomer.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.searchedCustomer = action.payload.data
            })
            .addCase(getSearchedCustomer.rejected, (state, action) => {
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
                state.dukandarLoading = false
            }) // getSearchedCustomer


            .addCase(updateDukanCustomer.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
            })
            .addCase(updateDukanCustomer.fulfilled, (state, action) => {
                console.log(action.payload)
                state.dukandarLoading = false
                state.searchedCustomer = action.payload.data
                state.dukandarSuccess = action.payload.success_message
                state.dukanCustomer = state.dukanCustomer ? state.dukanCustomer.map((item, index)=>{
                    return item.id === action.payload.data.id ? {...action.payload.data} : {...item}
                }) : null
            })
            .addCase(updateDukanCustomer.rejected, (state, action) => {
                console.log(action.payload)
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = null
            }) // updateDukanCustomer

            .addCase(getDukanAllCategory.pending, (state) => {
                state.dukanAllCategory = []
            })
            .addCase(getDukanAllCategory.fulfilled, (state, action) => {
                state.dukanAllCategory = action.payload.data
            })
            .addCase(getDukanAllCategory.rejected, (state, action) => {
                state.dukanAllCategory = []
                state.dukandarError = action.payload.error_message
            }) //getDukanAllCategory

            .addCase(getDukanCategory.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
            })
            .addCase(getDukanCategory.fulfilled, (state, action) => {
                state.dukanCategory = state.dukanCategory === null ? action.payload.data : [...state.dukanCategory, ...action.payload.data]
                state.dukandarLoading = false
                state.dukanCategoryFirstTime = false
            })
            .addCase(getDukanCategory.rejected, (state, action) => {
                state.dukandarLoading = false
                action.payload.error_message === errorName.invalidPage ?
                    state.dukanCategoryLastPage = true :
                    state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) // getDukanCategory


            .addCase(addDukanCategory.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.dukandarErrorType = null
            })
            .addCase(addDukanCategory.fulfilled, (state, action) => {
                state.dukandarSuccess = action.payload.success_message
                state.dukandarLoading = false
                state.dukanAllCategory = [...state.dukanAllCategory, action.payload.data.name]
                state.dukanCategory = state.dukanCategory ? [action.payload.data, ...state.dukanCategory] : state.dukanCategory
            })
            .addCase(addDukanCategory.rejected, (state, action) => {
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
                state.dukandarLoading = false
            }) // addDukanCategory


            .addCase(getSearchedCategory.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.searchedCategory = null
                state.dukandarErrorType = null
            })
            .addCase(getSearchedCategory.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.searchedCategory = action.payload.data
            })
            .addCase(getSearchedCategory.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) // getSearchedCategory


            .addCase(updateDukanCategory.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.dukandarErrorType = null
            })
            .addCase(updateDukanCategory.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.dukandarSuccess = action.payload.success_message
                const list = state.dukanAllCategory.filter((item) => {
                    return item !== action.payload.old_name
                })
                state.dukanAllCategory = [...list, action.payload.data.name]
                state.searchedCategory = action.payload.data
            })
            .addCase(updateDukanCategory.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) // updateDukanCategory


            .addCase(getCategoryProduct.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.categoryProduct = null
                state.dukandarErrorType = null
            })
            .addCase(getCategoryProduct.fulfilled, (state, action) => {
                state.categoryProduct = state.categoryProduct === null ? action.payload.data : [...action.payload.data, ...state.categoryProduct]
                state.dukandarLoading = false
            })
            .addCase(getCategoryProduct.rejected, (state, action) => {
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
                state.dukandarLoading = false
            }) // getCategoryProduct


            .addCase(addDukanProduct.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
                state.dukandarSuccess = null
            })
            .addCase(addDukanProduct.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.dukandarSuccess = action.payload.success_message
            })
            .addCase(addDukanProduct.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) // addDukanProduct


            .addCase(getSearchedProduct.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
                state.dukandarSuccess = null
            })
            .addCase(getSearchedProduct.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.searchedProduct = action.payload.data
            })
            .addCase(getSearchedProduct.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) // getSearchedProduct


            .addCase(updateDukanProduct.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.dukandarErrorType = null
            })
            .addCase(updateDukanProduct.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.dukandarSuccess = action.payload.success_message
                state.searchedProduct = action.payload.data
            })
            .addCase(updateDukanProduct.rejected, (state, action) => {
                console.log(action.payload)
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) // updateDukanProduct



            .addCase(getDukanOrder.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
            })
            .addCase(getDukanOrder.fulfilled, (state, action) => {
                state.dukanOrder = state.dukanOrder === null ? action.payload.data : [...state.dukanOrder, ...action.payload.data]
                state.dukandarLoading = false
                state.dukanOrderFirstTime = false
            })
            .addCase(getDukanOrder.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukanOrderFirstTime = false
                action.payload.error_message === errorName.invalidPage ?
                    state.dukanOrderLastPage = true :
                    state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) //getDukanOrder


            .addCase(getDukanNotification.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
            })
            .addCase(getDukanNotification.fulfilled, (state, action) => {
                state.dukanNotification = action.payload.data
                state.dukandarLoading = false
            })
            .addCase(getDukanNotification.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) //getDukanNotification


            .addCase(addDukanNotification.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarError = null
                state.dukandarErrorType = null
            })
            .addCase(addDukanNotification.fulfilled, (state, action) => {
                state.dukanNotification = state.dukanNotification ? [action.payload.data, ...state.dukanNotification] : [action.payload.data]
                state.dukandarSuccess = action.payload.success_message
            })
            .addCase(addDukanNotification.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) //addDukanNotification


            .addCase(deleteDukanNotification.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarError = null
                state.dukandarErrorType = null
            })
            .addCase(deleteDukanNotification.fulfilled, (state, action) => {
                state.dukanNotification.length === 1 ? state.dukanNotification = null :
                    state.dukanNotification = state.dukanNotification.filter((item, index) => {
                        return item.id !== action.payload.id
                    })
                state.dukandarSuccess = 'Notification Successfully Deleted !'
            })
            .addCase(deleteDukanNotification.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) //deleteDukanNotification


            .addCase(getDukanProfile.pending, (state) => {
                state.dukanProfile = null
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
            })
            .addCase(getDukanProfile.fulfilled, (state, action) => {
                state.dukanProfile = action.payload.data
                state.dukandarLoading = false
            })
            .addCase(getDukanProfile.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) //getDukanProfile


            .addCase(udpateDukanProfile.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarErrorType = null
            })
            .addCase(udpateDukanProfile.fulfilled, (state, action) => {
                state.dukanProfile = action.payload.data
                state.dukandarLoading = false
                state.dukandarSuccess = action.payload.success
            })
            .addCase(udpateDukanProfile.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
            }) //udpateDukanProfile

            .addCase(saveOrderPdf.pending, (state) => {
                state.dukandarError = null
                state.dukandarSuccess = null
                state.dukandarErrorType = null
            })
            .addCase(saveOrderPdf.fulfilled, (state, action) => {
                console.log(action.payload)
                state.dukandarSuccess = action.payload.success_message
                if (action.payload.updatedOrder) {
                    state.dukanOrder = state.dukanOrder ? state.dukanOrder.map((item)=>{
                          return item.id === action.payload.updatedOrder.id ? {...action.payload.updatedOrder} : {...item}
                    })
                    : null
                }
                else {
                    state.dukanOrder = state.dukanOrder ? [action.payload.newOrder, ...state.dukanOrder] : null
                }
            })
            .addCase(saveOrderPdf.rejected, (state, action) => {
                console.log('error')
                state.dukandarError = action.payload.error_message
                state.dukandarErrorType = action.payload.type
                // state.dukandarLoading = ac
            })

    }
})

export default dukandarSlice;
export { getDukanAllCategory, getDukanCustomer, addDukanCustomer, getSearchedCustomer, updateDukanCustomer, getDukanCategory, addDukanCategory, getSearchedCategory, updateDukanCategory, addDukanProduct, getSearchedProduct, updateDukanProduct, getDukanUser, getDukanProfile, udpateDukanProfile, getCategoryProduct, getDukanNotification, addDukanNotification, deleteDukanNotification, getDukanOrder,saveOrderPdf };
export const { dukandarDefault, setDukandarError, incDukanCustomerPage, incDukanCategoryPage, incDukanUserPage, setCategoryName, incDukanOrderPage, changeFilter, applyFilter, updateLinkCustomerInDuaknOrder, addNewOrder, updateDukanOrder, setSearchedProduct, setSearchedCategory, setSearchedCustomer, actionWhenCustomerlinkDislinkWithUser } = dukandarSlice.actions