import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {catchError, getToken, handleRequest, url} from '../base'
import axios from 'axios'

// creating intial state

const intialState = {
    cartLoading : true,
    cartSuccess : null,
    cartError : null,
    cartErrorType : null,
    total_price : 0,
    cart : null,
    address : null
}

const baseUrl = `${url}/api`


// creating api for the User
const getCartItem = createAsyncThunk('getCartItem', async (_, { rejectWithValue }) =>{
      try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/cartItem/`, {
            headers : {
                Authorization : `Bearer ${token}`
            },
        })
        return handleRequest(request, rejectWithValue)
     }
     catch (error) {
        return rejectWithValue(catchError(error))
     }
});

const orderComplete = createAsyncThunk('orderComplete', async ({data}, { rejectWithValue }) =>{
    try {
        const token = getToken();
        const request = await axios.put(`${baseUrl}/order/complete/`, data, {
            headers : {
                Authorization : `Bearer ${token}`
            },
        })
        console.log(request)
        return handleRequest(request, rejectWithValue)
     }
     catch (error) {
        console.log(error)
        return rejectWithValue(catchError(error))
     }
})

const cartSlice = createSlice({
     name : 'cart',
     initialState : intialState,
     reducers : {
        cartDefault(state, action){
            state.cartError = null
            state.cartSuccess = null
            state.cartErrorType = null
        },
        changeTotalPrice(state, action) {
            if(action.payload.type === 'del') {
                state.total_price = state.total_price - action.payload.amount
            }
            else {
                state.total_price = action.payload.amount 
            }
        },
        setCartError(state, action) {
            state.cartError = action.payload
            state.cartErrorType = 'exception'
        }
     },
     extraReducers: (builder) => {
         builder
            .addCase(getCartItem.pending, (state) =>{
                state.cart = null
                state.cartLoading = true
                state.cartError = null
                state.cartSuccess = null
                state.cartErrorType = null
            })
            .addCase(getCartItem.fulfilled, (state, action) =>{
                state.cartLoading = false
                console.log('getCartitem', action.payload.data)
                state.cart = action.payload.data
                state.total_price = action.payload.total_price
                state.address = action.payload.address
            })
            .addCase(getCartItem.rejected, (state, action) =>{
                 state.cartLoading = false
                 state.address = action.payload.address
                 state.cartError = action.payload.error_message
                 state.cartErrorType = action.payload.type
            }) //getCartItem

            
            .addCase(orderComplete.pending, (state) =>{
                 state.cartLoading = true
                 state.cartError = null
                 state.cartSuccess = null
                 state.cartErrorType = null
            })
            .addCase(orderComplete.fulfilled, (state, action) =>{
                 state.cartSuccess = action.payload.success_message
                 state.cartLoading = false
            })
            .addCase(orderComplete.rejected, (state, action) =>{
                console.log(action.payload)
                state.cartLoading = false
                state.cartError = action.payload.error_message
                state.cartErrorType = action.payload.type
            })
     }      
})

export default cartSlice;
export { getCartItem, orderComplete };
export const { cartDefault, changeTotalPrice, setCartError } = cartSlice.actions
