import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {NoData, ErrorUp, SuccessUp, catchError, Sloading, url, errorName, getToken } from '../base/index'
import { useSelector } from 'react-redux'
import { ProductReviewList } from './index'

function Reviews({ productId, setPerror, page, setPage, setTotalReview, updateAvgRating, reviewd}) {
    const user = useSelector((state) => state.userInfo.user)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [loadMore, setLoadMore] = useState(true)
    const [success, setSuccess] = useState(null)
    const [reviews, setReviews] = useState(null)
    const [isReviewd, setIsReviewd] = useState(false)
    const [data, setData] = useState({
        'username': null,
        'rating': 5,
        'comment': ''
    })
        

    async function getReviews(page) {
        setError(null)
        setIsLoading(true)
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/product/${productId}/reviews/`, {
                params: {
                    page: page
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data.error_message){
                request.data.error_message === errorName.invalidPage ? setLoadMore(false) : request.data.error_message === errorName.internalError ? setPerror(request.data.error_message) :
                setError(request.data.error_message)
            }
            else {
                reviews === null ? setReviews(request.data.data) : setReviews((prev) => { return [...prev, ...request.data.data] })
            }
            setIsLoading(false)
        }
        catch (error) {
            setIsLoading(false)
            setPerror(catchError(error).error_message)
        }
    }

    function change(e) {
        setData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    async function add() {
        if (data.rating < 1 || data.rating > 5) {
            setError('Enter 1 to 5 rating!')
        }
        else if (data.comment.trim() === '') {
            setError('Enter review!')
        }
        else if (data.comment.trim().length > 200) {
            setError('Maximum 200 words review !')
        }
        else {
            try {
                const token = getToken()
                const request = await axios.post(`${url}/api/product/${productId}/reviews/`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                if (request.data && request.data.error_message) {
                    request.data.error_message === errorName.internalError ? setPerror(request.data.error_message) : setError(request.data.error_message)
                    request.data.error_message === 'You already reivewd !' && setIsReviewd(true)
                }
                else  {
                    setSuccess('Thanks for the review!')
                    setReviews((prev) => {
                        return prev !== null ? [data, ...prev] : [data]
                    })
                    setIsReviewd(true)
                    setTotalReview((prev)=>{
                        return prev+1
                    })
                    updateAvgRating(data.rating)
                }
            }
            catch (error) {
                setPerror(catchError(error).error_message)
            }
            setData({
                'username': user,
                'rating': 5,
                'comment': ''
            })
        }
    }

    useEffect(() => {
        productId ? setIsReviewd(reviewd) : <></>
    },[reviewd])

    useEffect(() => {
        getReviews(page)
    }, [ page])

    useEffect(() => {
        user ? setData({
            'username': user,
            'rating': 5,
            'comment': ''
        }) : <></>
    }, [user])


    return (
        <>
            {
                (page === 1 && isLoading === true) ? <div style={{display : 'flex', justifyContent : 'center'}}> <Sloading color={'black'} width={'25px'} height={'fit-content'} /> </div> :
                    <>
                    {
                        reviews !== null ? <div className='mt-2 mb-2'>
                            {
                                reviews.map((item, key) => {
                                    return (
                                        <ProductReviewList key={key} item={item}/>
                                    )
                                })
                            }
                            {
                                isLoading ? <Sloading color={'black'} width={'25px'} height={'fit-content'} /> :
                                    loadMore === true ?
                                        <button className='secondry-button h6' style={{width : '130px'}} onClick={() => setPage((page) => page + 1)}>Load more</button> : <></>
                            }
                            <hr />
                        </div> : <> <hr /> <NoData message='Oops! No reviews, Be the 1st to review.' height='fit-content' />  </>
                    }
                    {
                        is_dukandar === false ? isReviewd === false ? 
                        <>
                            <div className='d-flex mt-2' style={{ alignItems: 'center', gap: '10px', marginBottom: '20px' }}>
                                <label className='h5'>Rating : </label>
                                <select className='form-select h6' style={{ width: 'fit-content' }} name='rating' value={data.rating} onChange={(e) => change(e)} >
                                    <option value={5}>5</option>
                                    <option value={4}>4</option>
                                    <option value={3}>3</option>
                                    <option value={2}>2</option>
                                    <option value={1}>1</option>
                                </select>
                            </div>
                            <textarea rows={4} className='h5' style={{ width: '100%', maxWidth : '500px' }} type='text' name='comment' value={data.comment} onChange={(e) => change(e)} placeholder='Product Review' /> <br />
                            <button className='h6 secondry-button mt-4 mb-2' style={{ width: '200px' }} onClick={() => add()}>submit</button>
                        </> : <h4 className='h6'>you already reviewd</h4> : <> </>
                    }
                    </> 
            }
            {
                error ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
            }
        </>
    )
}

export default Reviews