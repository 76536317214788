import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {catchError, errorName, getToken, handleRequest, url} from '../base/index'
import axios from 'axios'

// creating intial state

const intialState = {
    orderLoading : true,
    orderSuccess : null,
    orderError : null,
    orderErrorType : null,
    total_order : 0,
    orders : null,
    orderPage : 1,
    orderLastPage : false,
    orderFirstTime : true
}

const baseUrl = `${url}/api`


// creating api for the User
const getAllOrder = createAsyncThunk('getAllOrder', async (page, { rejectWithValue }) =>{
      try {
        const token = getToken();
        const request = await axios.get(`${baseUrl}/order/?page=${page}`, {
            headers : {
                Authorization : `Bearer ${token}`
            },
        })
        return handleRequest(request, rejectWithValue)
     }
     catch (error) {
        return rejectWithValue(catchError(error))
     }
})


const getLatestOrder = createAsyncThunk('getLatestOrder', async (_, { rejectWithValue }) =>{
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getLatestOrder/`, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return catchError(error)
    }
})


const orderSlice = createSlice({
     name : 'order',
     initialState : intialState,
     reducers : {
        incOrderPage(state, action) {
            state.orderPage = state.orderPage + 1
        },
        nullOrder(state, action) {
            state.orders = null
            state.orderPage = 1
            state.orderFirstTime = true
            state.orderLastPage = false
        },
        setOrderError(state, action) {
            state.orderError = action.payload
        }
     },
     extraReducers: (builder) => {
         builder
            .addCase(getAllOrder.pending, (state) =>{
                state.orderLoading = true
                state.orderError = null
                state.orderSuccess = null
                state.orderErrorType = null
            })
            .addCase(getAllOrder.fulfilled, (state, action) =>{
                state.orderLoading = false
                state.orders = state.orders === null ? action.payload.data : [...state.orders, ...action.payload.data,]
                state.orderFirstTime = false
                // state.total_order = action.payload.total_order
            })
            .addCase(getAllOrder.rejected, (state, action) =>{
                console.log(action.payload)
                 state.orderLoading = false
                 action.payload.error_message === errorName.invalidPage ?  
                 state.orderLastPage = true :
                 state.orderError = action.payload.error_message
                 state.orderErrorType = action.payload.type
            }) // 
            
            .addCase(getLatestOrder.pending, (state) =>{
                state.orderError = null
                state.orderErrorType = null
            })
            .addCase(getLatestOrder.fulfilled, (state, action) =>{
                state.orders = state.orders === null ? [action.payload.data] : [action.payload.data, ...state.orders]
            })
            .addCase(getLatestOrder.rejected, (state, action) =>{
                state.orderError = action.payload.error_message

            })
     }      
})

export default orderSlice;
export { getAllOrder, getLatestOrder };
export const { incOrderPage, nullOrder, setOrderError } = orderSlice.actions
// export const { cartDefault, changeTotalPrice } = cartSlice.actions
