import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import { catchError, Loading, SearchIcon, errorName, message, url, NoConnection, NoData, NotAuthorized, actionOnEnter, ErrorUp, CrossIcon, getToken } from '../base/index'
import { useSelector } from 'react-redux'
import axios from 'axios'
import SingleOrder from './SingleOrder'

function OrderSearch() {
  const { is_dukandar, user, userInfoLoading } = useSelector((state) => state.userInfo)
  const [searchKey, setSearchKey] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const location = useLocation()
  const key = new URLSearchParams(location.search).get('searchKey')
  const [orders, setOrders] = useState(null)
  const path = window.location.pathname
  const navigate = useNavigate()

  async function searchOrder(key) {
    console.log('calinngg api with', key)
    try {
      setOrders(null)
      setLoading(true)
      const token = getToken()
      const request = await axios.get(`${url}/api/user/order/search/?searchKey=${key}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      console.log('requesti----', request)
      if (request.data && request.data.error_message) {
        setError(request.data.error_message)
      }
      else {
        setOrders(request.data.data)
      }
      setLoading(false)
    }
    catch (error) {
      console.log('errroror', error)
      setLoading(false)
      setError(catchError(error).error_message)
    }
  }

  function search(searchKey, e = null) {
    if (!searchKey || (searchKey && searchKey.trim() === '')) {
      setError('Enter search Key')
    }
    else {
      if (is_dukandar) {
        e ? actionOnEnter(e, navigate, `/dukandar/orders/search/?searchKey=${encodeURIComponent(searchKey.trim())}`) :
          navigate(`/dukandar/orders/search/?searchKey=${encodeURIComponent(searchKey.trim())}`)
      }
      else {
        e ? actionOnEnter(e, navigate, `/orders/search/?searchKey=${encodeURIComponent(searchKey.trim())}`) :
          navigate(`/orders/search/?searchKey=${encodeURIComponent(searchKey.trim())}`)
      }
    }
  }

  useEffect(() => {
    if (key && key !== searchKey) {
      setSearchKey(key)
    }
    key && searchOrder(key)
  }, [key])

  return (
    <section>
      <Helmet>
        <title>Order Search</title>
      </Helmet>

      {
        loading || userInfoLoading ? <Loading /> :
          error === errorName["404Error"] || error === errorName.internalError ? <NoConnection /> :
            error === errorName.networkError ? <NoConnection network='true' /> :
              user === null || error === errorName.authenticationError ? <NotAuthorized path={`${path}${location.search}`} /> :
                path && is_dukandar === true && !path.includes('/dukandar/') ? <NoData message={message.notGrahak} dukandar={false} /> :
                  path && is_dukandar === false && path.includes('/dukandar/') ? <NoData message={message.notDukandar} dukandar={true} /> :
                    <>
                      <div className='heading h4'>Search Order with Id or Name</div>
                      <div style={{ width: '300px', margin: 'auto', position: 'relative', marginBottom: '15px' }}>
                        <input className='h6' onChange={(e) => setSearchKey(e.target.value)} value={searchKey} placeholder='Order ID / Name ' />
                        <span onClick={() => setSearchKey('')} style={{ position: 'absolute', right: '0px' }}><CrossIcon style={{ fill: 'gray' }} /></span>
                        <span onClick={() => search(searchKey)} style={{ position: 'absolute', right: '35px', top: '3px' }}> <SearchIcon style={{ fill: 'red' }} /></span>
                      </div>
                      {
                        orders ? orders.map((item, index) => {
                          return (
                            <div key={index} className='container'>
                              <SingleOrder item={item} setPerror={setError} is_dukandar={is_dukandar} />
                            </div>
                          )
                        }) :
                          <NoData message='Oops! No order found with this search key' />
                      }
                      {error ? <ErrorUp message={error} removeMessage={setError} /> : <></>}

                    </>
      }
    </section>
  )
}

export default OrderSearch;