import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dropdown } from "../../redux/UserInfoSlice";
import { getDukanOrder, incDukanOrderPage, applyFilter, changeFilter, dukandarDefault } from '../../redux/DukandarSlice'
import { Helmet } from "react-helmet";
import { Loading, NoData, NoConnection, NotAuthorized, errorName, message, Sloading, RightArrowIcon, SearchIcon, NotFound404 } from "../../base/index";
import { SingleOrder } from "../../components/index";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "../../base/svg/RefreshIcon";


function DukanOrder() {
    const { userInfoLoading, user, is_dukandar } = useSelector((state) => state.userInfo)
    const { dukandarLoading, dukandarError, dukandarErrorType, dukanOrder, dukanOrderFirstTime, dukanOrderLastPage, dukanOrderPage, orderFilter } = useSelector((state) => state.dukandar)
    const [error, setError] = useState(null)
    const [isMoreLoading, setIsMoreLoading] = useState(false)
    const path = window.location.pathname
    const dispatch = useDispatch()
    const navigate = useNavigate()

    function load() {
        setIsMoreLoading(true)
        dispatch(getDukanOrder({ 'page': dukanOrderPage + 1, 'filters': orderFilter }))
        dispatch(incDukanOrderPage())
    }

    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])

    // useEffect(()=>{
    //     dukandarErrorType === 'normal' ? dispatch(dukandarDefault()) : <></>
    // },[dukandarErrorType])

    useEffect(() => {
        dukanOrderFirstTime ? dispatch(getDukanOrder({ "page": 1, 'filters': orderFilter })) : <></>
    }, [dukanOrderFirstTime])

    useEffect(() => {
        dukandarLoading === false ? setIsMoreLoading(false) : <></>
    }, [dukandarLoading])

    useEffect(() => {
        dukandarError !== null && dukandarErrorType === 'normal' && dispatch(dukandarDefault())
    }, [dukandarError])

    console.log(dukandarLoading, userInfoLoading)
    return (
        <section className="dukanorder">
            <Helmet>
                <title>Dukan Orders</title>
            </Helmet>

            <div className='container'>
                {
                    (dukanOrderPage === 1 && dukandarLoading) || userInfoLoading ? <Loading /> :
                        dukandarError === errorName["404Error"] || error === errorName["404Error"] ? <NotFound404 /> :
                             dukandarError === errorName.internalError || error === errorName.internalError ? <NoConnection /> :
                                dukandarError === errorName.networkError || error === errorName.networkError ? <NoConnection network='true' /> :
                                    user === null || dukandarError === errorName.authenticationError || error === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                        is_dukandar === false || error === errorName.notDukandar || dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                                            <>
                                                <div className="filters row mt-3 me-0">
                                                    <div className="col-6 d-flex pe-0" style={{ alignItems: 'center' }}>
                                                        <label className='h6 me-2' htmlFor="">Deliverd : </label>
                                                        <select value={orderFilter.is_deliverd} name='is_deliverd' style={{ fontSize: '15px', padding: 0, width: 'fit-content' }} onChange={(e) => dispatch(changeFilter({ 'type': 'order', 'name': 'is_deliverd', 'value': e.target.value }))}>
                                                            <option value='all'>All</option>
                                                            <option value='True'>YES</option>
                                                            <option value='False'>NO</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-4 d-flex pe-0 ps-0" style={{ alignItems: 'center' }}>
                                                        <label className='h6 me-2' htmlFor="">Paid : </label>
                                                        <select value={orderFilter.is_paid} name='is_paid' style={{ fontSize: '15px', width: 'fit-content' }} onChange={(e) => dispatch(changeFilter({ 'type': 'order', 'name': 'is_paid', 'value': e.target.value }))}>
                                                            <option value='all'>All</option>
                                                            <option value='True'>YES</option>
                                                            <option value='False'>NO</option>
                                                        </select>
                                                    </div>

                                                    <button className="col-2 h6 secondry-button ps-3 pe-3 pt-1 pb-1 me-0" onClick={() => dispatch(applyFilter({ 'type': 'order' }))} style={{ width: 'fit-content' }}><RightArrowIcon style={{ width: '15px' }} className='fa-beat' /></button>
                                                </div>
                                                <hr />

                                                {
                                                    dukanOrder !== null ?
                                                        <div className='order'>
                                                            <h1 className='heading h4 mt-2'>Dukan Orders!</h1>
                                                            {
                                                                dukanOrder.map((item, key) => {
                                                                    return (
                                                                        <SingleOrder item={item} key={key} setPerror={setError} is_dukandar={is_dukandar} />
                                                                    )
                                                                })
                                                            }
                                                            <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                {
                                                                    isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                                                        dukanOrderLastPage === false ?
                                                                            <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                                                                }
                                                            </div>
                                                            <span className='me-3 me-md-5 ' onClick={() => navigate('/dukandar/orders/search/')} style={{ position: 'absolute', top: '150px', right: '0px' }}><SearchIcon style={{ fill: 'red', width: '25px ' }} /></span>
                                                            <span className="ms-3 ms-sm-5" onClick={()=>dispatch(applyFilter({'type' : 'order'}))} style={{position : 'absolute', top : '150px', fill : 'red', left : '0px', display : 'flex', flexDirection : 'column', alignItems : 'center'}}><RefreshIcon/><h1 style={{fontSize : '11px'}}>Refresh</h1></span>
                                                        </div> : <NoData message={'Oops! No order found in your dukan with this filters.'} />
                                                }
                                            </>
                }
            </div>
        </section>
    )
}

export default DukanOrder;