import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dropdown, changeDefaultDukan, changeTotalItem, notificationChange, setDefaultDukanProfile } from "../../redux/UserInfoSlice";
import { url, catchError, Sloading, SuccessUp, getToken, errorName, message, ErrorUp, getMapSrc, openLink, SearchIcon, CrossIcon, GroupWhatsappIcon, fallbackImg, GeoLocationIcon, LockIcon, getDate, NotFound404 } from "../../base/index";
import { Loading, NoConnection, NoData, NotAuthorized } from "../../base/index";
import axios from "axios";
import { Helmet } from "react-helmet";
import { nullCategory } from "../../redux/ProductSlice";
import { nullOrder } from "../../redux/OrderSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { DukanReviews, Stars, LazyImage } from "../../components";

function ApniDukan() {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(true)
    const [selecting, setSelecting] = useState(false)
    const [dukanProfile, setDukanProfile] = useState(null)
    const [locationError, setLocationError] = useState(null)
    const path = window.location.pathname
    const baseUrl = `${url}/api/user`
    const dispatch = useDispatch()
    const { userInfoLoading, default_dukan_id, default_dukan_profile, user, is_dukandar, default_dukan_name } = useSelector((state) => state.userInfo)
    const location = useLocation()
    const dukan_id = new URLSearchParams(location.search).get('dukan_id')
    const [dukans, setDukans] = useState(null)
    const [pin, setPin] = useState('')
    const [dukan, setDukan] = useState({ 'name': '', 'id': '' })
    const navigate = useNavigate()

    // get default dukan on 1st time and when selection is clicked
    async function getApniDukan(dukan_id, set_default_dukan) {
        setDukans(null)
        if (dukanProfile && dukan_id === dukanProfile.id) { }
        else {
            setLoading(true)
            setError(null)
            setDukanProfile(null)
            try {
                const token = getToken()
                const request = await axios.get(`${baseUrl}/getApniDukan/?dukan_id=${dukan_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                console.log(request.data)
                setLoading(false)
                if (request.data && request.data.error_message) {
                    setError(request.data.error_message)
                }
                else {
                    set_default_dukan ? dispatch(setDefaultDukanProfile(request.data.data)) :
                        setDukanProfile(request.data.data)
                    setDukan({ 'name': request.data.data.name, 'id': dukan_id })
                }
            }
            catch (error) {
                console.log('here is the error', error)
                setLoading(false)
                setError(catchError(error).error_message)
            }
        }
    }

    console.log(dukan, error, loading)
    // setapni dukan
    async function setApniDukan(dukan_id) {
        if (dukan_id === default_dukan_profile.id) {
            setSuccess(dukan.name + ' is now, apki apni dukan !')
        }
        else {
            setSelecting()
            setError(null)
            setSuccess(null)
            try {
                const token = getToken()
                const request = await axios.put(`${baseUrl}/setApniDukan/?dukan_id=${dukan_id}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setSelecting(false)
                if (request.data && request.data.error_message) {
                    setError(request.data.error_message)
                }
                else {
                    dispatch(changeDefaultDukan({ 'id': dukan_id, 'name': dukanProfile.name, 'default_dukan_profile': dukanProfile }))
                    dispatch(changeTotalItem({ 'type': 'specific', 'total_item': request.data.total_item }))
                    dispatch(notificationChange({ 'data': request.data.notification }))
                    dispatch(nullCategory())
                    dispatch(nullOrder())
                    setSuccess(request.data.success_message)
                }
            }
            catch (error) {
                setSelecting(false)
                setError(catchError(error).error_message)
            }
        }
    }


    // when a dukan in drop down or suggestion is cliked it is selected
    function selectDukan(item) {
        setDukan({ 'name': item.name, 'id': item.id })
        getApniDukan(item.id)
    }

    // search the dukan with pin 
    async function searchDukan(location = null) {
        if (location === null && pin.length !== 6) {
            setError('Please Enter 6 digit Pin !')
        }
        else {
            setError(null)
            setDukans(null)
            try {
                const request = location ? await axios.get(`${url}/api/user/searchApniDukan/?latitude=${location.latitude}&longitude=${location.longitude}`) : await axios.get(`${url}/api/user/searchApniDukan/?pin=${pin.trim()}`)
                console.log(request.data)
                request.data.error_message ? setError('Oops! No dukan found with this pin.') : setDukans(request.data)
            }
            catch (error) {
                setError('Oop! No dukan found with this pin.')
            }
        }
    }


    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])
    console.log(dukans)
    // runs

    useEffect(() => {
        if (dukan_id) {
            console.log('calingg with dukan id ', dukan_id)
            getApniDukan(dukan_id)
        }
        else if (default_dukan_profile === null && default_dukan_id) {
            console.log('calingg with dukandporfline', default_dukan_id)
            getApniDukan(default_dukan_id, true)
        }
        else if (default_dukan_profile) {
            setDukanProfile(default_dukan_profile)
            setLoading(false)
        }
        else if (!user) {
            console.log('setinngg loading false', user)
           setLoading(false)
        }
    }, [default_dukan_profile, default_dukan_id, dukan_id])

    // useEffect(() => {
    //     dukan_id ? getApniDukan(dukan_id) : default_dukan_id ? getApniDukan(default_dukan_id) : setLoading(false)
    // }, [default_dukan_id, dukan_id])

    function funlocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log(position)
                    searchDukan({ 'latitude': position.coords.latitude, 'longitude': position.coords.longitude })
                },
                (err) => {
                    err.message === 'User denied Geolocation' ? setLocationError(true) : <></>
                    console.log('err', err);
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    return (
        <section className="apnidukan" style={{ position: 'relative', paddingBottom: '20px', overflow: 'hidden' }}>
            <Helmet>
                <title>Apni Dukan</title>
            </Helmet>
            {
                loading || userInfoLoading ? <Loading /> :
                    error === errorName["404Error"] ? <NotFound404 /> :
                        error === errorName.internalError ? <NoConnection /> :
                            error === errorName.networkError ? <NoConnection network='true' /> :
                                user === null || error === errorName.authenticationError ? <NotAuthorized path={encodeURIComponent(`${path}${location.search}`)} /> :
                                    is_dukandar === true || error === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                        <>
                                            <div className="h6" style={{ textAlign: 'center', marginTop: '10px', overflow: 'hidden' }}>
                                                Apki Apni Dukan :<div className="heading h5 mt-0" style={{ cursor: 'pointer' }} onClick={() => navigate('/user/apniDukan/')}> {default_dukan_name}</div>
                                            </div>
                                            <div style={{ maxWidth: '280px', margin: 'auto', justifyContent: 'center', overflow: 'visible' }}>
                                                <div className='searchlist'>
                                                    <div className='searchlistinput' style={{ width: '100%', overflow: 'visible' }}>
                                                        <input className='h6 pb-1' style={{ flex: 1, width: '100%' }} value={pin} onChange={(e) => setPin(e.target.value)} type='number' placeholder='Your Pin - 451224' />
                                                        {
                                                            dukans === null ?
                                                                <span onClick={() => searchDukan()}>
                                                                    <SearchIcon style={{ position: 'absolute', right: '10px', top: '-4px', cursor: 'pointer', fill: 'red' }} />
                                                                </span>
                                                                :
                                                                <span onClick={() => setDukans(null)}>
                                                                    <CrossIcon style={{ position: 'absolute', right: '10px', top: '-5px', fill: 'gray', overflow: 'hidden', cursor: 'pointer', width: '18px' }} />
                                                                </span>
                                                        }
                                                    </div>
                                                    {/* <span onClick={() => funlocation()} style={{ position: 'absolute', right: '-10px', top: '-4px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}><GeoLocationIcon style={{ cursor: 'pointer', fill: 'red', backgroundColor: 'white', width: '20px' }} /><h1 style={{ fontSize: '10px' }}>My Location</h1></span> */}


                                                </div>
                                            </div>
                                            <div style={dukans ? { transition: 'all .5s ease-in-out', maxWidth: '800px', opacity: '1', display: 'flex', justifyContent: 'center', margin: 'auto' } : { transition: 'all .5s ease-in-out', maxWidth: '800px', opacity: '0' }}>
                                                <div className="d-flex gap-3 ps-2 pe-2 mt-3" style={{ overflow: 'scroll', scrollbarWidth: 'none' }}>
                                                    {
                                                        dukans && dukans.map((item, index) => {
                                                            return (
                                                                <div style={{ flex: 'none', width: '220px', height: '180px', border: '1.3px solid gray', borderRadius: '10px', cursor: 'pointer' }} onClick={() => selectDukan(item)} key={index}>
                                                                    <LazyImage src={item.banner_image} alt={item.name} height ={110} width={220} />
                                                                    <div className="ps-1 pe-1 pb-0">
                                                                        <h1 style={{ fontSize: '14px', color: 'red' }} className="truncate1">{item.name} </h1>
                                                                        <h1 style={{ fontSize: '11px' }} className="truncate1" >{item.address}</h1>
                                                                        <h1 style={{ fontSize: '10px' }} className="truncate2">{item.about}</h1>
                                                                        <h1 style={{ fontSize: '10px', display: 'flex', gap: '5px', justifyContent: 'space-between' }}><span>Reviews : {item.total_reviews}</span> <span className="d-flex">Rating : {item.avg_rating} <Stars rating={item.avg_rating} /></span> </h1>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <hr className="mb-1 mt-3 w-75 m-auto" />

                                            {
                                                dukanProfile ? <div className=' apniDukan' style={{ maxWidth: '1100px', width: '95%', margin: 'auto', }}>
                                                    <div className="h5" style={{ textAlign: 'center', marginTop: '20px', overflow: 'hidden' }}>
                                                        <div className="heading h4 mt-2">{dukanProfile.name}, <span style={{ color: 'black', textDecoration: 'none' }}>Details</span></div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                                        <div className='dukandetail mt-3 d-flex' style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                            <img src={dukanProfile.banner_image} alt='banner' className='img-fluid bannerimage' />
                                                            <div className='ownerimage m-auto mt-4'>
                                                                <img src={dukanProfile.owner_image} className='img-fluid' style={{ height: '100%', width: '100%' }} alt='Malik' />
                                                            </div>

                                                            <h5 className='h4 mt2 mb-2 heading'>{dukanProfile.owner}</h5>
                                                            <p className='h6 m-auto' style={{ width: 'fit-content', textAlign: 'center' }}>{dukanProfile.about}</p>

                                                            <div className='dukandetail row g-3 mt-5'>
                                                                <div className="col-12">
                                                                    <span style={{ color: 'red' }}>Joined On : <span style={{ color: 'black' }}> {dukanProfile && getDate(dukanProfile.joined_on)} </span></span>
                                                                </div>
                                                                <div className='col-sm-5 col-md-6 '>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Dukan Id : </span>MKD-{dukanProfile.id}</h1>
                                                                </div>
                                                                <div className='col-sm-7 col-md-6'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Owner : </span>{dukanProfile.owner}</h1>
                                                                </div>
                                                                <div className='col-sm-5 col-md-6'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Dukan : </span>{dukanProfile.name}</h1>
                                                                </div>
                                                                <div className='col-sm-7 col-md-6'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Address : </span>{dukanProfile.address}, {dukanProfile.city}</h1>
                                                                </div>
                                                                <div className='col-sm-5 col-md-6'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Phone no. : </span>{dukanProfile.phone_number}</h1>
                                                                </div>
                                                                <div className='col-sm-7 col-md-6'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Whatsapp no. : </span>{dukanProfile.whatsapp_number}</h1>
                                                                </div>
                                                                <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Total Products : </span>{dukanProfile.total_product}</h1>
                                                                </div>
                                                                <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Total Category : </span>{dukanProfile.total_category}</h1>
                                                                </div>
                                                                <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Total Orders : </span>{dukanProfile.total_order}</h1>
                                                                </div>
                                                                <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Total Users : </span>{dukanProfile.total_user}</h1>
                                                                </div>
                                                                <div className='12'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Delivery Available in : </span>{dukanProfile.available_in}</h1>
                                                                </div>
                                                                <div className='col-12'>
                                                                    <h1 className='h6'><span style={{ color: 'red' }}>Other Details : </span></h1>
                                                                    {
                                                                        dukanProfile.other_details.split('/n').map((item, key) => {
                                                                            return <p key={key} className='h6'>{key + 1}. {item}</p>
                                                                        })
                                                                    }
                                                                </div>

                                                                {
                                                                    getMapSrc(dukanProfile.map_location) ?
                                                                        <div className='col-12 dukanmap' style={{ textAlign: 'center' }}>
                                                                            <h1 className='h5 mt-2 mb-3'><span style={{ color: 'red' }}>Location </span></h1>
                                                                            <iframe title="mahajan kirana map" src={getMapSrc(dukanProfile.map_location)} style={{ border: "0", height: 'inherit', width: '100%' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                                        </div> : <></>
                                                                }
                                                                <DukanReviews reviews={dukanProfile.reviews} total_reviews={dukanProfile.total_reviews} avg_rating={dukanProfile.avg_rating} is_reviewd={default_dukan_profile.is_reviewd} can_review={default_dukan_id === dukanProfile.id} setPerror={setError} />

                                                            </div>

                                                            <button className='secondry-button h6 pt-1 pb-1 mt-3' style={{ width: '200px' }} onClick={() => setApniDukan(dukan.id, true)}>{selecting ? <Sloading /> : 'Select Dukan'}</button>
                                                        </div>

                                                        {/* whatsapp group icon */}
                                                        {
                                                            dukanProfile.whatsapp_link !== null && dukanProfile.whatsapp_link.trim() !== '' &&
                                                            <div style={{ right: '13px', position: 'absolute', bottom: '10px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1px', cursor: 'pointer' }} onClick={() => openLink(dukanProfile.whatsapp_link)}>
                                                                <span>
                                                                    <GroupWhatsappIcon style={{ lineHeight: 'normal', width: '30px', fill: 'green' }} />
                                                                </span>
                                                                <h6 style={{ fontSize: '12px', color: 'green' }}>Join Group</h6>
                                                            </div>
                                                        }

                                                    </div>
                                                </div> : <NoData message='Oops! No dukan found, please select another dukan' />
                                            }
                                            {
                                                error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
                                            }
                                        </>

            }



            {locationError &&
                <div className="d-flex" style={{ justifyContent: 'center' }}>
                    <div style={{ position: 'fixed', top: 20, width: '320px', backgroundColor: 'white', zIndex: 100, border: '1.3px solid gray', padding: '5px', left: '40%', borderRadius: '5px' }}>
                        <h5 style={{ fontSize: '14px', color: 'red', textAlign: 'center' }}>Please allow permission !</h5>
                        <h6 className="text-center" style={{ fontSize: '15px' }}>To get your nearby dukans..</h6>
                        <h6 className="d-flex" style={{ fontSize: '13px' }}> * Click the <LockIcon className="ms-1 me-1" style={{ fill: 'red', width: '13px' }} /> icon in search tab (top-left corner) </h6>
                        <h6 style={{ fontSize: '13px' }}>* Now click the location button to <span style={{ color: 'red' }}>On</span> or select <span style={{ color: 'red' }}>Allow</span></h6>
                        <h6 style={{ fontSize: '13px' }}>* Then Refresh / Reload the Page</h6>
                        <span style={{ position: 'absolute', top: '5px', right: '15px' }} onClick={() => setLocationError(null)}><CrossIcon style={{ fill: 'gray' }} /></span>
                    </div>
                </div>
            }

        </section>
    )
}

export default ApniDukan;


