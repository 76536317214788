// import everyting
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getUser, updateUser, userDefault, resetPassword, setUserErrorType, setUserError } from '../../redux/UserSlice'
import { Loading, NotAuthorized, NoData, ErrorUp, SuccessUp, NoConnection, pattern, errorName, message, scrollFocus, nextOnEnter, NotFound404 } from '../../base/index'
import { dropdown, userInfoChange } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'
import { ResetPassword } from '../../components'

// main function
function Profile() {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [data, setData] = useState(null)
  const { user, userInfoLoading, is_dukandar } = useSelector((state) => state.userInfo)
  const { userLoading, userData, userError, userSuccess, userErrorType } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const path = window.location.pathname
  const refrence = {
    usernameRef: useRef(null),
    emailRef: useRef(null),
    fullnameRef: useRef(null),
    houseRef: useRef(null),
    streetRef: useRef(null),
    colonyRef: useRef(null),
    cityRef: useRef(null),
    numberRef: useRef(null),
  }


  function change(e) {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }


  function update() {
    const trimed_username = data.username.trim()
    const trimed_email = data.email.trim()
    if (trimed_username === '') {
      setError('Enter username!')
      scrollFocus(refrence.usernameRef)
    }
    else if (!pattern.usernamePattern.test(trimed_username)) {
      setError("Use only these characters ' a-z A-Z 0-9 _ @ $ ' in username field !")
      scrollFocus(refrence.usernameRef)
    }
    else if (trimed_email === '' || !pattern.emailPattern.test(trimed_email)) {
      setError('Enter a valid email !')
      scrollFocus(refrence.emailRef)
    }
    else if (data.order_username.trim() === '') {
      setError('Enter full name !')
      scrollFocus(refrence.fullnameRef)
    }
    else if (data.order_house_no < 0 || data.order_house_no === '') {
      setError('Enter house number!')
      scrollFocus(refrence.houseRef)
    }
    else if (data.order_street_no < 0 || data.order_street_no === '') {
      setError('Enter street / ward number !')
      scrollFocus(refrence.streetRef)
    }
    else if (data.order_colony.trim() === '') {
      setError('Enter colony / mohalla / area name !')
      scrollFocus(refrence.colonyRef)
    }
    else if (data.order_city.trim() === '') {
      setError('Enter city / village name !')
      scrollFocus(refrence.cityRef)
    }
    else if (String(data.order_number).length !== 10) {
      setError('Enter 10 digits whatsapp number!')
      scrollFocus(refrence.numberRef)
    }
    else {
      const { order_pin, ...newData } = data
      dispatch(updateUser({ 'data': newData }))
    }
  }


  useEffect(() => {
    dispatch(dropdown(false))
    if (userData === null) {
      console.log('calinng apiii')
      dispatch(getUser())
    }
    else { setData(userData) }
  }, [userData])

  console.log(userError, userErrorType, userInfoLoading, userSuccess)
  useEffect(() => {
    if (userSuccess === 'Data updated') {
      setSuccess('Profile updated!')
      dispatch(userInfoChange(userData?.username))
      dispatch(userDefault())
    }
    else if (userSuccess === 'Password has been reset!') {
      setSuccess('Password reset!')
      dispatch(userDefault())
    }
    else if (userError === 'username : user with this username already exists.') {
      setError('Username already exiest! Try new username!')
      dispatch(userDefault())
    }
    else if (userErrorType === 'normal') {
      setError(userError)
      dispatch(userDefault())
    }
  }, [userSuccess, userError])

  console.log(userErrorType, userError, data)
  return (
    <section className='profile container'>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      {
        userLoading || userInfoLoading ? <Loading /> :
          userError === errorName['404Error'] ? <NotFound404 /> :
            userError === errorName.internalError ? <NoConnection /> :
              userError === errorName.networkError ? <NoConnection network='true' /> :
                user === null || userError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                  is_dukandar === true || userError === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                    <>
                      {
                        data !== null ?
                          <div className='row ps-2 pe-2 p-sm-0' style={{ rowGap: '20px' }}>
                            <div className='h4 heading mb-2'>Your Profile</div>
                            <div>Id : MKU-{data.id}</div>
                            <div className='col-sm-6'>
                              <label className='h5'>Username :</label>
                              <input ref={refrence.usernameRef} onKeyDown={(e) => nextOnEnter(e, refrence.emailRef)} className='h6' type='text' name='username' onChange={(e) => change(e)} value={data.username} placeholder='Your username ex - kamalmahajan' />
                            </div>
                            <div className='col-sm-6'>
                              <label className='h5'>Email :</label>
                              <input ref={refrence.emailRef} onKeyDown={(e) => nextOnEnter(e, refrence.fullnameRef)} className='h6' type='eamil' name='email' onChange={(e) => change(e)} value={data.email} placeholder='example@gmail.com' />
                            </div>

                            <div className='h4 heading'>Default Delivery Address</div>
                            <div className='col-sm-12 col-md-6'>
                              <label className='h5'>Full Name :</label>
                              <input ref={refrence.fullnameRef} onKeyDown={(e) => nextOnEnter(e, refrence.houseRef)} className='h6' type='text' name='order_username' onChange={(e) => change(e)} value={data.order_username} placeholder='kamal Ramnarayan Mahajan' />
                            </div>
                            <div className='col-5 col-md-2 col-lg-3'>
                              <label className='h5'>House No. :</label>
                              <input ref={refrence.houseRef} onKeyDown={(e) => nextOnEnter(e, refrence.streetRef)} className='h6' type='number' name='order_house_no' onChange={(e) => change(e)} value={data.order_house_no} placeholder='171' />
                            </div>
                            <div className='col-7 col-md-4 col-lg-3'>
                              <label className='h5'>Street No. / Ward No. : </label>
                              <input ref={refrence.streetRef} onKeyDown={(e) => nextOnEnter(e, refrence.colonyRef)} className='h6' type='number' name='order_street_no' onChange={(e) => change(e)} value={data.order_street_no} placeholder='3' />
                            </div>
                            <div className='col-sm-12 col-md-6'>
                              <label className='h5'>Calony / Mohalla / Area: </label>
                              <input ref={refrence.colonyRef} onKeyDown={(e) => nextOnEnter(e, refrence.cityRef)} className='h6' type='text' name='order_colony' onChange={(e) => change(e)} value={data.order_colony} placeholder='Dhan Mandi Tilak Marg' />
                            </div>
                            <div className='col-sm-6 col-md-3'>
                              <label className='h5'>City / Village : </label>
                              <input ref={refrence.cityRef} onKeyDown={(e) => nextOnEnter(e, refrence.numberRef)} className='h6' type='text' name='order_city' onChange={(e) => change(e)} value={data.order_city} placeholder='Maheshwar' />
                            </div>
                            <div className='col-sm-6 col-md-3'>
                              <label className='h5'>Whatsapp Number : </label>
                              <input ref={refrence.numberRef} onKeyDown={(e) => nextOnEnter(e, refrence.usernameRef)} className='h6' type='number' name='order_number' onChange={(e) => change(e)} value={data.order_number} placeholder='use only whatsapp number' />
                            </div>

                            <div className='col-md-6 text-md-start text-center pt-2 pb-2' >
                              <button className='primary-button third-button h6 pt-1 pb-1' style={{ maxWidth: '200px' }} onClick={() => update()}>Update  All Details</button>
                            </div>

                            <hr />
                            <ResetPassword dispatch={dispatch} />

                            {
                              error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                            }
                          </div> : <NoData message='Oops! No data found for this user.' />
                      }
                    </>
      }
    </section>
  )
}

export default Profile