import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { defaultCategoryProductsData, getCategoryProducts } from '../../redux/ProductSlice'
import { Loading, NotAuthorized, NoData, NoConnection, Sloading, errorName, message, NotFound404 } from '../../base/index'
import { SingleProduct } from '../../components/index'
import { dropdown } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'

function CategoryProduct() {
    const { category } = useParams()
    const { categoryProductData, productError, productLoading } = useSelector((state) => state.product)
    const { is_dukandar, userInfoLoading } = useSelector((state) => state.userInfo)
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [isMoreLoading, setIsMoreLoading] = useState(false)
    const path = window.location.pathname

    function load() {
        setIsMoreLoading(true)
        dispatch(getCategoryProducts({ 'page': page + 1, 'category': encodeURIComponent(category) }))
        setPage((prev) => {
            return prev + 1
        })
    }

    useEffect(() => {
        dispatch(dropdown(false))
        dispatch(defaultCategoryProductsData())
    }, [dispatch])


    useEffect(() => {
        categoryProductData === null ? dispatch(getCategoryProducts({ 'page': 1, 'category': encodeURIComponent(category) })) : <></>
    }, [categoryProductData])

    useEffect(() => {
        productLoading === false && setIsMoreLoading(false)
    }, [productLoading])


    return (
        <section>
            <Helmet>
                <title>{category} products</title>
            </Helmet>
            {
                (page === 1 && productLoading === true) || userInfoLoading ? <Loading /> :
                    productError === errorName['404Error'] ? <NotFound404 /> :
                        productError === errorName.internalError ? <NoConnection /> :
                            productError === errorName.networkError ? <NoConnection network='true' /> :
                                productError === errorName.authenticationError ? <NotAuthorized path={encodeURIComponent(path)} /> :
                                    productError === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                        categoryProductData !== null ?
                                            <div className='my-container'>
                                                <div className='h4 heading mb-2'>{category} Products</div>
                                                <div className='row mt-0'>
                                                    {categoryProductData.map((item, key) => {
                                                        return (
                                                            <SingleProduct item={item} key={key} dukandar={is_dukandar} />
                                                        )
                                                    })}
                                                </div>
                                                <hr />
                                                <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                    {
                                                        isMoreLoading ? <Sloading color={'red'} width={'30px'} height={'fit-content'} /> :
                                                            productError !== errorName.invalidPage ?
                                                                <button className='h6 secondry-button ps-2 pe-2' style={{ width: '200px' }} onClick={() => load()}>Load more</button> : <></>
                                                    }
                                                </div>
                                            </div> :
                                            <NoData message='Oops! No products found for this category.' />
            }
        </section>
    )
}

export default CategoryProduct