import React, { useState } from "react";
import { changeTotalItem } from "../redux/UserInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ErrorUp, SuccessUp, catchError, url, Sloading, errorName, CartIcon, DiscountIcon, fallbackImg, getToken } from "../base/index";
import { setOrderError } from "../redux/OrderSlice";
import LazyImage from "./LazyImage";

const SingleOrderItem = React.memo(({ item, setPerror }) => {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)
    const [imgSrc, setImgSrc] = useState(fallbackImg)
    const dispatch = useDispatch()

    async function add() {
        setSuccess(null)
        setError(null)
        try {
            setLoading(true)
            const token = getToken()
            const request = await axios.post(`${url}/api/cartItem/`, { 'product': item.product_id, 'quantity': item.quantity, 'button' : 'add' },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            if (request.data && request.data.error_message) {
                request.data.error_message === errorName.internalError ? setPerror(request.data.error_message) :
                setError(request.data.error_message)
            }
            else {
                request.data.success_message.includes('added') ? dispatch(changeTotalItem('inc')) : <></>
                setSuccess(request.data.success_message)
            }
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
               setPerror(catchError(error).error_message)
        }

    }

    return (
        <>
            <div className="orderitem mt-3" style={{ position: 'relative' }}>
                <div className="row">
                    <div className="col-3 p-0">
                        <div className="orderimg" >
                            <LazyImage src={item.image} className='img-fluid' alt={item.product_name}/>
                            {/* <img src={imgSrc} onLoad={()=>setImgSrc(item.image)} style={imgSrc === fallbackImg ? {opacity : '0.6'} : {opacity : 1}} className="img-fluid" alt={item.product_name} /> */}
                        </div>
                    </div>
                    <div className="detail col-9">
                        <div className="row g-2">
                            <div className="col-12 text-start">
                                <h1 className="h6">{item.product_name}</h1>
                            </div>
                            <div className="col-6">
                                <h1 className="h6">Quantity : {item.quantity}</h1>
                            </div>
                            <div className="col-6">
                                <h1 className="h6">Total : {item.price} Rs.</h1>
                            </div>
                            {is_dukandar === false ? <>
                                <div className="col-9">
                                    <h1 className="h6">Today's Price : {item.product_price} Rs.</h1>
                                </div>
                                <div className="col-3">
                                    {
                                        loading === true ?
                                            <button className='secondry-button h6 d-flex' style={{ justifyContent: 'center', alignItems: 'center' }} ><Sloading color={'red'} width={'16px'} height={'fit-content'} /></button> :
                                            <button className='secondry-button h6 d-flex' style={{justifyContent : 'center', alignItems : 'center'}} onClick={() => add()}><CartIcon style={{overflow : 'hidden'}}/></button>  
                                    }
                                    {/* <button className="secondry-button h6" disabled={loading ? true : false} onClick={() => add()}><i className="fa-solid fa-cart-shopping"></i></button> */}
                                </div>
                            </> : <div className="col-12">
                                <h1 className="h6">Today's Price : {item.product_price} Rs.</h1>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {
                        item.item_offer ? <>
                            <div className='discounticon'> <DiscountIcon className=" fa-solid fa-layer-group fa-fade h6" /></div>
                            <div className='offer'>
                                <div>{item.item_offer}</div>
                            </div>
                        </> : <></>
                    }
                {
                    error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                }
            </div>
            <hr className="mt-1 mb-1"></hr>
        </>
    )
})

export default SingleOrderItem;