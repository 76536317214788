import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrder, getLatestOrder, incOrderPage } from "../../redux/OrderSlice";
import { errorName, Loading, message, NoConnection, NoData, NotAuthorized, NotFound404, SearchIcon, Sloading } from "../../base/index";
import { SingleOrder } from "../../components/index";
import { dropdown } from "../../redux/UserInfoSlice";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function Orders() {
    const { orderLoading, orderError, orders, orderLastPage, orderPage, orderFirstTime } = useSelector((state) => state.order)
    const { user, default_dukan_name, userInfoLoading, is_dukandar } = useSelector((state) => state.userInfo)
    const [isMoreLoading, setIsMoreLoading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    const path = window.location.pathname
    const navigate = useNavigate()

    function load() {
        setIsMoreLoading(true)
        dispatch(getAllOrder(orderPage + 1))
        dispatch(incOrderPage())
    }
    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])


    useEffect(() => {
        orderFirstTime === true ? dispatch(getAllOrder(1)) : <></>
    }, [orderFirstTime])

    useEffect(() => {
        orderLoading === false ? setIsMoreLoading(false) : <></>
    }, [orderLoading])
console.log(orderLastPage)

    return (
        <section>
            <Helmet>
                <title>My Orders</title>
            </Helmet>
            {
                (orderPage === 1 && orderLoading) || userInfoLoading === true ? <Loading /> :
                    orderError === errorName["404Error"] || error === errorName["404Error"] ? <NotFound404 /> :
                        orderError === errorName.internalError || error === errorName.internalError ? <NoConnection /> :
                            orderError === errorName.networkError || error === errorName.networkError ? <NoConnection network='true' /> :
                                user === null || orderError === errorName.authenticationError || error === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                    is_dukandar === true || orderError === errorName.notGrahak || error === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                        orders !== null ?
                                            <div className='order container'>
                                                <div className="h4 text-center mt-3"></div>
                                                <h1 className='heading h4 mt-0 mb-1'>Your previous orders</h1>
                                                <div className="text-center mb-2">with " {default_dukan_name} "</div>
                                                {
                                                    orders.map((item, key) => {
                                                        return (
                                                            <SingleOrder item={item} key={key} setPerror={setError} />
                                                        )
                                                    })
                                                }

                                                {
                                                    <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                        {
                                                            isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                                                orderLastPage === false ?
                                                                    <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                                                        }
                                                    </div>
                                                }
                                                <span className='me-3 me-md-5 ' onClick={() => navigate('/myOrders/search/')} style={{ position: 'absolute', top: '90px', right: '0px' }}><SearchIcon style={{ fill: 'red', width: '25px ' }} /></span>

                                            </div> : <NoData message={'Oops! No order found in this dukan, Shop now. '} />
            }
        </section>
    )
}

export default Orders