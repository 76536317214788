import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ErrorUp, SuccessUp, Sloading, scrollFocus, nextOnEnter } from '../../base/index'
import { forgotPassword, userDefault } from '../../redux/UserSlice'
import { SmallLogo } from '../../components/index'
import { dropdown } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'
import { errorName, pattern } from '../../base'

function Forgot() {
  const user = useSelector((state) => state.userInfo.user)
  const { userError, userSuccess, userLoading, userErrorType, userAction } = useSelector((state) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const next = new URLSearchParams(location.search).get('redirect');
  const [redirect, setRedirect] = useState('/')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [forgotData, setForgotData] = useState({
    'username': '',
    'email': ''
  })
  const refrence = {
    usernameRef: useRef(null),
    emailRef: useRef(null)
  }

  // document.addEventListener('keypress', handleEnter)
  // function handleEnter(event) {
  //   event.key === 'Enter' ? forgot() : <></>
  // }

  function change(e) {
    setForgotData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }


  function forgot() {
    const trimed_username = forgotData['username'].replace(/\s+/g, '')
    const trimed_email = forgotData['email'].trim()
    if (trimed_username === '') {
      setError('Enter username!')
      scrollFocus(refrence.usernameRef)
    }
    else if (!pattern.usernamePattern.test(trimed_username)) {
      setError("Use only these characters ' a-z A-Z 0-9 _ @ $ ' in username field !")
      scrollFocus(refrence.usernameRef)
    }
    else if (trimed_email === '' || !pattern.emailPattern.test(trimed_email)) {
      setError('Enter a valid email !')
      scrollFocus(refrence.emailRef)
    }
    else {
      dispatch(forgotPassword({ 'username': forgotData.username, 'email': forgotData.email }))
    }
  }

  // useEffect
  useEffect(() => {
    if (userAction === 'forgotPassword' && userError && userErrorType === 'exception') {
      dispatch(userDefault())
      setError('Oops! can not perform operation, Please try again.')
    }
    else if (userAction === 'forgotPassword' && userError && userErrorType === 'normal') {
      setError(userError)
      dispatch(userDefault())
    }
    else if (userSuccess === 'email sent !') {
      setSuccess("New password sent to your email address! please check in ' spam ' also.")
      dispatch(userDefault())
    }
  }, [userError, userSuccess])

  useEffect(() => {
    next === null ? setRedirect('/') : setRedirect(next)
  }, [next])

  useEffect(() => {
    user !== null && userSuccess === null ? navigate('/') : <></>
  }, [user])

  useEffect(() => {
    dispatch(dropdown(false))
  }, [dispatch])

  return (
    <section className='forgot container'>:
      <Helmet>
        <title style={{ 'fontSize': '2px' }}>Forgot Password</title>
      </Helmet>
      <div className='row' style={{ width: '100%' }}>
        <div className='logoside col-12 col-md-6 d-flex' style={{ justifyContent: 'center' }}>
          <SmallLogo />
        </div>
        <div className='formside col-12 col-md-6 col-lg-5 col-xl-4 offset-xl-1' >
          <div className='card forgot-card gap-2' data-aos="fade-down" >
            <div className='h4 text-center mb-2' style={{ textDecoration: 'underline' }}>Forgot Password</div>
            <label className='h5' htmlFor="">Username : </label>
            <input ref={refrence.usernameRef} onKeyDown={(e) => nextOnEnter(e, refrence.emailRef)} className='h6' type="text" name='username' value={forgotData.username} onChange={(e) => change(e)} placeholder='Enter username' />
            <label className='h5' htmlFor="">Email : </label>
            <input ref={refrence.emailRef} onKeyDown={(e) => nextOnEnter(e, refrence.usernameRef)} className='h6' type="email" name='email' value={forgotData.email} onChange={(e) => change(e)} placeholder='example@gmail.com' />
            <label style={{ fontSize: '0.8rem' }}>Please check our email in spam also</label>
            <button className='primary-button h5 pt-1 pb-1' onClick={() => forgot()} disabled={userLoading ? true : false}>{userLoading ? <Sloading color={'red'} width={'18px'} height={'fit-content'} /> : 'Send email'}</button>
            {/* <button className='primary-button h5' onClick={() => forgot()} disabled={userLoading ? true : false}>Send email</button> */}
            <div className='divider h6'>OR</div>
            <div className='' style={{ textAlign: 'center', fontSize: '14px' }}>Already have account ?</div>
            <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/loginUser/?redirect=${redirect}`)} disabled={userLoading ? true : false}>Login</button>
            <div className='' style={{ textAlign: 'center', fontSize: '14px' }}>Don't have Mahajan Kirana account ?</div>
            <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/createUser/?redirect=${redirect}`)} disabled={userLoading ? true : false}>Create Account</button>
          </div>
        </div>
        {
          error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
        }
      </div>
    </section>
  )
}

export default Forgot