import { useDispatch, useSelector } from "react-redux";
import { BilldeskIcon, CartIcon, CategoryIcon, CustomerIcon, DiscountIcon, fallbackImg, getToken, NotificationIcon, OrderIcon, ProductIcon, ShopIcon, Sloading, url, UsersIcon } from '../base/index'
import { Link, NavLink } from 'react-router-dom'
import { dropdown } from "../redux/UserInfoSlice";
import { useEffect, useState } from "react";
import axios from "axios";
import HomeIcon from "../base/svg/HomeIcon";
import { removeToken } from "../base/CodeEasier";
import LazyImage from "./LazyImage";

const baseUrl = `${url}/api`

// function
function Header() {

  // variable declaration
  const { user, total_item, userInfoLoading, notification, user_dropdown, search_dropdown, side_offcanvas, is_dukandar } = useSelector((state) => state.userInfo)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [products, setProducts] = useState([])

  async function productSearch(word) {
    try {
      const token = getToken()
      const request = await axios.get(`${baseUrl}/product/search/?word=${word}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      console.log(request.data)
      request.data && setProducts(request.data)
      setLoading(false)
    }
    catch (error) { }
  }

  function logout() {
    dispatch(dropdown(false))
    removeToken()
    // dispatch(userInfoDefault())
    // dispatch(changeTotalItem('set0'))
    // dispatch(nullCategory())
    // dispatch(nullOrder())
    window.location.href = '/'
  }


  useEffect(() => {
    if (search.trim().length > 2) {
      setLoading(true)
      const time = setTimeout(() => {
        productSearch(search)
      }, [1000])
      dispatch(dropdown({ 'type': 'search_dropdown', 'value': true }))
      return () => clearTimeout(time)
    }
    else {
      dispatch(dropdown({ 'type': 'search_dropdown', 'value': false }))
    }
  }, [search])
  console.log('proidct', products)
  // return
  return (
    <>

      {/* navigation bar */}
      <div className="nav">
        <div className="container header">
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <svg className='menuIcon' onClick={() => dispatch(dropdown({ 'type': 'side_offcanvas', 'value': true }))} href="#offcanvasExample" role="button" aria-controls="offcanvasExample" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" /></svg>
            {/* <svg className='menuIcon' onClick={() => dispatch(dropdown({ 'type': 'side_offcanvas', 'value': true }))} href="#offcanvasExample" role="button" aria-controls="offcanvasExample" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" /></svg> */}
            <NavLink className="navbar-brand" to={'/'}><span>M</span><span>K</span></NavLink>
            {
              is_dukandar === false ? <div className="d-none d-lg-flex gap-4 overflow-visible ms-4">
                <NavLink className="h5 navLink" to={'/category/'}>Category</NavLink>
                <NavLink className="h5 navLink" to={'/myOrders/'}>MyOrders</NavLink>
                <NavLink className="h5 navLink" to={'/user/apniDukan/'}>Dukan</NavLink>
                <NavLink className="h5 navLink" to={'/product/SnapNShop/'}>SNS</NavLink>
                <NavLink className="h5 navLink" to={'/cartItem/'}>Cart  {total_item}</NavLink>
              </div> :
                <div className="d-none d-lg-flex gap-4 overflow-visible ms-1 me-4">
                  <NavLink className="h5 navLink" to={'/dukandar/customers/'}> Customers</NavLink>
                  <NavLink className="h5 navLink" to={'/dukandar/category/'}> Category</NavLink>
                  <NavLink className="h5 navLink" to={'/dukandar/product/'}>Products</NavLink>
                  <NavLink className="h5 navLink" to={'/dukandar/orders/'}>Orders</NavLink>
                  {/* <NavLink className="h5 navLink" to={'/dukandar/profile/'}>Profile</NavLink> */}
                  <NavLink className="h5 navLink" to={'/dukandar/billDesk/'}>Bill Desk</NavLink>

                </div>

            }
          </div>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            {
              user !== null ?
                <>
                  <div className=" d-block me-2 me-sm-2 me-md-0" style={{ position: 'relative', width: '275px' }}>
                    <input className="nav-search snsinput h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search proudcts here." />
                    <svg onClick={() => setSearch('')} style={{ position: 'absolute', width: '17px', top: '3px', right: '2px', fill: 'gray', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                  </div>
                </> : <></>
            }
            {
              userInfoLoading === true ? <Sloading height={'auto'} width={'25px'} color={'red'} /> :
                user === null ?
                  <>
                    <Link to={'/user/loginUser/'}><button className="primary-button h6 ps-3 pe-3 pt-auto pb-auto pt-md-1 pb-md-1 me-3 ms-0 ms-md-1 ms-lg-2" style={{ width: 'fit-content' }}>Login</button></Link>
                  </> :
                  <>
                    <button onClick={() => dispatch(dropdown({ 'type': 'user_dropdown', 'value': !user_dropdown }))} className="nav-user-button ms-0 ms-sm-4 me-2 me-sm-3 me-md-4"><i className={`h5 fa-solid fa-${user[0].toLowerCase()}`} style={{ flex: "none" }}></i></button>
                  </>
            }
          </div>
        </div>

        <div className={side_offcanvas ? "offcanvas offcanvas-start show" : 'offcanvas offcanvas-start'} tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title h4" id="offcanvasExampleLabel">Menu</h5>
            <button type="button" className="btn-close" onClick={() => dispatch(dropdown({ 'type': 'side_offcanvas', 'value': false }))} data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <hr style={{ margin: '0px 0px' }}></hr>
          <div className="offcanvas-body">
            <NavLink className="h5 link" to={'/'}><span className="link-icon"><HomeIcon className="fa-beat-fade" style={{ width: '20px' }} /></span> Home</NavLink>
            {is_dukandar === false ? <>
              <NavLink className="h5 link" to={'/category/'}><span className="link-icon"><CategoryIcon className="fa-beat" style={{ width: '20.5px' }} /></span>Category</NavLink>
              <NavLink className="h5 link" to={'/product/SearchAllProduct/'}><span className="link-icon"><ProductIcon className="fa-flip" style={{ width: '22.5px' }} /></span>Search Products</NavLink>
              <NavLink className="h5 link" to={'/notification/'}><span className="link-icon"><NotificationIcon className="fa-shake" style={{ width: '21.5px' }} /></span>Notifications {notification}</NavLink>
              <NavLink className="h5 link" to={'/product/SnapNShop/'}><span className="link-icon"><DiscountIcon className="fa-flip" style={{ width: '23.5px' }} /></span>SNS</NavLink>
              <NavLink className="h5 link" to={'/myOrders/'}><span className="link-icon"><OrderIcon className="fa-bounce" style={{ width: '16/5px' }} /></span>MyOrders</NavLink>
              <NavLink className="h5 link" to={'/cartItem/'}><span className="link-icon"><CartIcon className="fa-fade" style={{ width: '22.5px' }} /></span>Cart {total_item}</NavLink>
              <NavLink className="h5 link" to={'/user/apniDukan/'}><span className="link-icon"><ShopIcon className="fa-beat" style={{ width: '21.5px' }} /></span>About Apni Dukan</NavLink>
            </> : <>
              <NavLink className="h5 link" to={'/dukandar/users/'}><span className="link-icon"><UsersIcon className="fa-flip" style={{ width: '22.5px' }} /></span>Users</NavLink>
              <NavLink className="h5 link" to={'/dukandar/customers/'}><span className="link-icon"><CustomerIcon className='fa-fade' style={{ width: '22.5px' }} /></span>Customers</NavLink>
              <NavLink className="h5 link" to={'/dukandar/category/'}><span className="link-icon"><CategoryIcon className="fa-shake" style={{ width: '21.5px' }} /></span>Category</NavLink>
              <NavLink className="h5 link" to={'/dukandar/product/'}><span className="link-icon"><ProductIcon className="fa-spin" style={{ width: '22.5px' }} /></span>Products</NavLink>
              <NavLink className="h5 link" to={'/dukandar/orders/'}><span className="link-icon"><OrderIcon className="fa-bounce" style={{ width: '16.5px' }} /></span>Orders</NavLink>
              <NavLink className="h5 link" to={'/dukandar/notification/'}><span className="link-icon"><NotificationIcon className="fa-shake" style={{ width: '20.5px' }} /></span>Notifications</NavLink>
              <NavLink className="h5 link" to={'/dukandar/profile/'}><span className="link-icon"><ShopIcon className="fa-beat" style={{ width: '20.5px' }} /></span>Dukan Profile</NavLink>
              <NavLink className="h5 link" to={'/dukandar/billDesk/'}><span className="link-icon"><BilldeskIcon className="fa-flip" style={{ width: '16.5px' }} /></span>Bill Desk</NavLink>
            </>
            }
          </div>
        </div>
      </div>

      {/* search bar dropdown */}
      <div className="container product-list">
        <div className={search_dropdown ? "list show" : 'list'} >
          <div className="listdiv">
            {
              loading === true ?
                <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '7px' }}><Sloading width={20} height={30} color='red' /></div> :
                products && products.length > 0 ?
                  <>
                    {products.map((item, key) => {
                      return (
                        <div className="productSearchingImg">
                          {is_dukandar === false ?
                            <Link key={key} to={`/product/${item.name.replace(/\s+/g, "-")}/`}><LazyImage src={item.image} alt="img" width='45px' height="40px" /> <h5 className="h6 ps-1 pe-1" key={key}>{item.name}</h5></Link> :
                            <Link key={key} to={`/dukandar/product/${item.name.replace(/\s+/g, "-")}/`}> <h5 className="h6 ps-1 pe-1 pt-2 pb-2" key={key}>{item.name}</h5></Link>
                          }
                          <hr style={{ margin: 0 }} />
                        </div>
                      )
                    })
                    }
                    {!is_dukandar && products && products.length > 0 && <Link to={`/product/SearchAllProduct/?p=${encodeURIComponent(search.trim())}`}> <h5 className="h6 ps-1 pe-1 pt-1 pb-1" >See All Product with this name</h5></Link>}
                  </>
                  : <> <h5 className="h6 ps-1 pe-1 pt-2 pb-2" >No Product Found</h5></>
            }
          </div>
        </div>
      </div>

      {/* profile dropdown */}
      {
        is_dukandar === false ?
          <div className="container profile-dropdown">
            <div className={user_dropdown ? "list show" : 'list'} style={{ zIndex: 11 }}>
              <div>
                <Link to={`/user/profile/`}><h5 className="h6">Profile</h5></Link>
                <hr style={{ margin: 0 }} />
                <h5 className="h6" onClick={() => logout()}>Log Out</h5>
              </div>
            </div>
          </div> :
          <div className="container profile-dropdown">
            <div className={user_dropdown ? "list show" : 'list'} style={{ zIndex: 11 }}>
              <div>
                <Link to={`/dukandar/profile/`}><h5 className="h6">Profile</h5></Link>
                <hr style={{ margin: 0 }} />
                <h5 className="h6" onClick={() => logout()}>Log Out</h5>
              </div>
            </div>
          </div>
      }

    </>

  )
}

export default Header;





// offcanvas search 
{/* <div className="d-none d-sm-none" style={{ position: 'relative', width: '240px' }}>
                <input className="snsinput h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search proudcts here." />
                <i className="h5 fa-solid fa-xmark" onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '9px', color: 'gray', cursor: 'pointer' }}></i>
              </div>
              <div className={search_dropdown ? "d-none offcanvas-list show" : 'd-none offcanvas-list'}>
                <div className="listdiv">
                  {
                    loading === true ?
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '7px' }}><Sloading width={20} height={30} color='blue' /></div> :
                      products && products.map((item, key) => {
                        return (
                          <>
                            <Link to={`/product/${item.name.replace(/\s+/g, "-")}/`}> <h5 className="h6" key={key}>{item.name}</h5></Link>
                            <hr style={{ margin: 0 }} />
                          </>
                        )
                      })
                  }
                </div>
              </div> */}