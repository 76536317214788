import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDukanCategory, incDukanCategoryPage, getDukanAllCategory, getSearchedCategory, setSearchedCategory } from "../../redux/DukandarSlice";
import { dropdown } from "../../redux/UserInfoSlice";
import { Helmet } from "react-helmet";
import { SingleCategory, AddDukanCategory, UpdateDukanCategory } from "../../components/index";
import { Loading, NoData, NotAuthorized, NoConnection, Sloading, errorName, message, NotFound404 } from "../../base/index";
import { useLocation, useNavigate } from "react-router-dom";


function DukanCategory() {
    const { user, is_dukandar, userInfoLoading } = useSelector((state) => state.userInfo)
    const { dukanCategory, dukandarLoading, dukandarError, dukanCategoryPage, dukanAllCategory, dukanCategoryFirstTime, dukanCategoryLastPage } = useSelector((state) => state.dukandar)
    const [isMoreLoading, setIsMoreLoading] = useState(false)
    const dispatch = useDispatch()
    const path = window.location.pathname
    const navigate = useNavigate()
    const location = useLocation()
    const action = new URLSearchParams(location.search).get('action')
    const ctu = new URLSearchParams(location.search).get('categoryToupdate')

    function load() {
        setIsMoreLoading(true)
        dispatch(getDukanCategory(dukanCategoryPage + 1))
        dispatch(incDukanCategoryPage())
    }


    useEffect(() => {
        dukanAllCategory.length === 0 ? dispatch(getDukanAllCategory()) : <></>
        dispatch(dropdown(false))
        return () => dispatch(setSearchedCategory(null))
    }, [dispatch])


    useEffect(() => {
        action === 'update' && ctu ? dispatch(getSearchedCategory(encodeURIComponent(ctu))) : <></>
    }, [action, ctu])

    useEffect(() => {
        dukanCategoryFirstTime === true ? dispatch(getDukanCategory(1)) : <></>
    }, [dukanCategoryFirstTime])


    useEffect(() => {
        dukandarLoading === false ? setIsMoreLoading(false) : <></>
    }, [dukandarLoading])
    console.log(action)
    return (
        <section className="dukancategory">
            <Helmet>
                <title>Dukan Category</title>
            </Helmet>

            <div>
                {
                    (dukanCategoryPage === 1 && dukandarLoading) || userInfoLoading ? <Loading /> :
                        dukandarError === errorName["404Error"] ? <NotFound404 /> :
                            dukandarError === errorName.internalError ? <NoConnection /> :
                                dukandarError === errorName.networkError ? <NoConnection network='true' /> :
                                    user === null || dukandarError === errorName.authenticationError ? <NotAuthorized path={encodeURIComponent(`${path}${location.search}`)} /> :
                                        is_dukandar === false || dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                                            <>
                                                <div className='addupdateoperation'>
                                                    <div onClick={() => navigate(`/dukandar/category/?action=category`)} className={action !== 'add' && action !== 'update' ? 'active h6' : 'h6'}>Category</div>
                                                    <div onClick={() => navigate(`/dukandar/category/?action=add`)} className={action === 'add' ? 'active h6' : 'h6'}>Add</div>
                                                    <div onClick={() => navigate(`/dukandar/category/?action=update`)} className={action === 'update' ? 'active h6' : 'h6'}>Update</div>
                                                </div>

                                                {
                                                    action === 'add' ?
                                                        <AddDukanCategory /> :
                                                        action === 'update' ?
                                                            <UpdateDukanCategory /> :
                                                            dukanCategory !== null ?
                                                                <div className='my-container'>
                                                                    {console.log('addddddddddddddddddddd')}
                                                                    <div className='heading h4 mb-4'>Different Categories</div>
                                                                    <div className='row'>
                                                                        {
                                                                            dukanCategory.map((item, key) => {
                                                                                return (
                                                                                    <SingleCategory item={item} key={key} dukandar={true} />
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <h4 />
                                                                    <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                        {
                                                                            isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                                                                dukanCategoryLastPage === false ?
                                                                                    <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                                                                        }
                                                                    </div>
                                                                </div> : <NoData message='Oops! No category found in your Dukan.' />
                                                }
                                            </>
                }
            </div>
        </section>
    )
}

export default DukanCategory;