import React, { useEffect, useRef, useState } from 'react'
import { ErrorUp, nextOnEnter, scrollFocus, SuccessUp } from '../base'
import { useDispatch, useSelector } from 'react-redux'
import { addDukanCustomer, dukandarDefault } from '../redux/DukandarSlice'

const AddDukanCustomer = React.memo(({data, setData, setOperationLoading}) => {
  const { dukandarError, dukandarSuccess, dukandarErrorType } = useSelector((state) => state.dukandar)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const refrence = {
    nameRef: useRef(null),
    numberRef: useRef(null),
    houseRef: useRef(null),
    streetRef: useRef(null),
    colonyRef: useRef(null),
    cityRef: useRef(null),
  }
  const dispatch = useDispatch()

  function change(e) {
    const { name, value } = e.target
    setData((prev) => {
      return {
        ...prev,
        [name]: name === 'link_user' ? !prev.link_user : name === 'userid' && value === '' ? null : value
      }
    })
  }

  function add() {
    console.log(data)
    const trimed_name = data.name.trim()
    if (trimed_name === ''){
      setError('Enter customer name !')
      scrollFocus(refrence.nameRef)
    }
    else if(data.number.toString().length != 10){
      setError('Enter 10 digit number !')
      scrollFocus(refrence.numberRef)
    }
    else if(data.house_no < 0 || data.house_no === '') {
      setError('Enter house number !')
      scrollFocus(refrence.houseRef)
    }
    else if(data.street_no < 0 || data.street_no === '') {
      setError('Enter street number !')
      scrollFocus(refrence.streetRef)
    }
    else {
      setOperationLoading(true)
      dispatch(addDukanCustomer(data))
    }
  }

  useEffect(() => {
    if(dukandarError && dukandarErrorType === 'normal') {
      setError(dukandarError) 
      dispatch(dukandarDefault())
     }
    else if(dukandarSuccess === 'Customer addedd !') {
       setSuccess('Customer added to your dukan !') 
       dispatch(dukandarDefault())
      }
  }, [dukandarError, dukandarSuccess])

  return (
    <div className='container'>
      <div className='row'>
        <div className='mb-5 col-md-6 col-lg-6'>
          <label className='h5'>Name : </label>
          <input className='h6' ref={refrence.nameRef} onKeyDown={(e) => nextOnEnter(e, refrence.numberRef)} type='text' name='name' value={data.name} onChange={(e) => change(e)} placeholder='Udit Mahajan' />
        </div>
        <div className='mb-5 col-md-6 col-lg-3'>
          <label className='h5'>Number : </label>
          <input className='h6' ref={refrence.numberRef} onKeyDown={(e) => nextOnEnter(e, refrence.houseRef)} type='number' name='number' value={data.number} onChange={(e) => change(e)} placeholder='1234567890' />
        </div>
        <div className='mb-5 col-md-6 col-lg-3'>
          <label className='h5'>House no. : </label>
          <input className='h6' ref={refrence.houseRef} onKeyDown={(e) => nextOnEnter(e, refrence.streetRef)} type='number' name='house_no' value={data.house_no} onChange={(e) => change(e)} placeholder='31' />
        </div>
        <div className='mb-5 col-md-6 col-lg-2'>
          <label className='h5'>Stree / Ward no. : </label>
          <input className='h6' ref={refrence.streetRef} onKeyDown={(e) => nextOnEnter(e, refrence.colonyRef)} type='number' name='street_no' value={data.street_no} onChange={(e) => change(e)} placeholder='5' />
        </div>
        <div className='mb-5 col-md-6 col-lg-7'>
          <label className='h5'>Colony : </label>
          <input className='h6' ref={refrence.colonyRef} onKeyDown={(e) => nextOnEnter(e, refrence.cityRef)} type='text' name='colony' value={data.colony} onChange={(e) => change(e)} placeholder='Dhan Mandi Tilak Marg' />
        </div>
        <div className='mb-5 col-md-6 col-lg-3'>
          <label className='h5'>City : </label>
          <input className='h6' ref={refrence.cityRef} onKeyDown={(e) => nextOnEnter(e, refrence.nameRef)} type='text' name='city' value={data.city} onChange={(e) => change(e)} placeholder='Maheshwar' />
        </div>
      </div>


      <br />
      <button className=' primary-button h6 ps-4 pe-4 pt-1 pb-1' style={{ width: 'fit-content' }} onClick={(e) => add()}>Add Customer</button>
      {
        error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
      }
    </div>
  )
})

export default AddDukanCustomer