import React, { useEffect, useState } from 'react'
import Stars from './Stars'
import { Sloading, catchError, getToken, url, ErrorUp, SuccessUp, NoData } from '../base'
import { Carousel } from 'react-bootstrap'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeDukanReivews } from '../redux/UserInfoSlice'

const DukanReviews = React.memo(({ avg_rating, is_reviewd, reviews, total_reviews, can_review, setPerror, dukandar = false }) => {
  const user = useSelector((state) => state.userInfo.user)
  const [dukanReviews, setDukanReviews] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [review, setReview] = useState({
    'username': null,
    'rating': 5,
    'review': ''
  })


  async function createReview() {
    setError(null)
    setSuccess(null)
    if (review.rating < 1 || review.rating > 5) {
      setError('Enter 1 to 5 rating!')
    }
    else if (review.review.trim() === '') {
      setError('Please write comment!')
    }
    else if (review.review.trim().length > 150) {
      setError('Maximum 150 words review!')
    }
    else {
      setLoading(true)
      try {
        const token = getToken()
        const request = await axios.post(`${url}/api/user/createDukanReview/`, review, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        console.log('request', request.data)
        if (request.data && request.data.error_message === 'User already reviewed!') {
          dispatch(changeDukanReivews({ 'type': 'only_is_reviewd', 'value': true }))
          setError('You already reviewd !')
        }
        else if (request.data && request.data.error_message) {
          setError(request.data.error_message)
        }
        else {
          console.log('revies', dukanReviews)
          dukanReviews ? setDukanReviews((prev) => { return { review, ...prev } }) : setDukanReviews(review)
          dispatch(changeDukanReivews({ 'is_reviewd': true, 'rating': review.rating, 'review': review }))
          setSuccess('Thanks for the review!')
        }
        setLoading(false)
      }
      catch (error) {
        setLoading(false)
        setPerror(catchError(error).error_message)
      }
    }
  }

  function change(e) {
    setReview((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  useEffect(() => {
    user && setReview((prev) => {
      return {
        ...prev, 'username': user
      }
    })
  }, [user])

  useEffect(() => {
    reviews && setDukanReviews(reviews)
  }, [reviews])
  console.log('revieee')

  return (
    <div className='col-12'>
      <hr />
      <h6 className='h6'><span style={{ color: 'red' }}>Total Reviews</span> : {total_reviews}</h6>
      <h6 className='h6 d-flex' style={{ alignItems: 'center' }}><span style={{ color: 'red' }}>Rating </span>  : {avg_rating} <Stars rating={avg_rating} /></h6>
      <div className='row mt-5 mb-5'>
        <div className='col-md-6 col-lg-6 left order-2 order-md-1' style={{ minHeight: '100px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderRight: '1px solid gray' }}>
          {
            loading ? <Sloading color={'red'} /> :
              !dukandar ?
                can_review ?
                  is_reviewd === false ?
                    <>
                      <div className="d-flex w-100" style={{ flexDirection: 'column' }}>
                        <div className='d-flex' style={{ alignItems: 'center' }}>
                          <label className="h6 ">Rating : </label>
                          <select className='form-select h6' style={{ width: 'fit-content' }} name='rating' value={review.rating} onChange={(e) => change(e)} >
                            <option value={5}>5</option>
                            <option value={4}>4</option>
                            <option value={3}>3</option>
                            <option value={2}>2</option>
                            <option value={1}>1</option>
                          </select>
                        </div>
                        <label className="h6 mt-3 mb-2">Comment : </label>
                        <textarea rows={3} className="h6" type='text' name='review' onChange={(e) => change(e)} value={review.review} placeholder="Maximum 115 words." />
                        <h6 style={{ fontSize: '13px', color: 'red', position: 'absolute', bottom: '20px' }}>words : {review.review.length}</h6>
                        <button className="secondry-button ps-5 pe-5 pt-1 pb-1 mt-2 mb-0" onClick={() => createReview()} style={{ width: 'fit-content' }}>submit</button>
                      </div>
                    </> :
                    is_reviewd === true ? <h3 className="h5">You already reviewd!</h3> :
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h5 className="h5 mb-4">Please login to Review</h5>
                        <Link to={'/user/loginUser/'} className="primary-button h6 m-0" style={{ padding: '5px 40px', width: 'fit-content' }}>Login</Link>
                      </div>
                  : <h5 className='h6 text-center' style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>Choose this dukan to review</h5>
                : <h5 className='h6 text-center' style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>Dukandar can not review🙏</h5>
          }
        </div>

        <div className='col-md-6 col-lg-6 order-1 order-md-2 text-center last mt-3 mb-5 mt-md-0 mb-md-0'>
          {
            dukanReviews !== null && dukanReviews.length > 0 ?
              <Carousel>
                {dukanReviews !== null && dukanReviews.map((item, key) => {
                  return (
                    <Carousel.Item key={key}>
                      <div className="singleappreview" key={key}>
                        <h1 className="h5" style={{ color: 'red' }}>{item.username}</h1>
                        <h1 className="h5 d-flex">{item.rating} <Stars rating={item.rating} /></h1>
                        <h1 className="h6">{item.review}</h1>
                      </div>
                    </Carousel.Item>
                  )
                })}
              </Carousel> :
              <NoData message='Oops! No reviews yet! Be the 1st to review' height='fit-content' />
          }
        </div>
      </div>
      <hr />
      {
        error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <> </>
      }
    </div>
  )
})

export default DukanReviews