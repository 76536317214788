import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css'; 
import { fallbackImg } from '../base/Variables';
import React, { useState } from 'react';

function LazyImage({src, alt, className, width = '100%', height = '', aspectRatio = 'auto'}) {
  const [error, setError] = useState(null)
  return (
    <LazyLoadImage onError={()=>setError(true)} className={className} style={{width : width, height : height, aspectRatio : aspectRatio}} alt={alt} src={error ? fallbackImg : src} effect="opacity" placeholderSrc={fallbackImg} loading= 'lazy' />
  )
}

export default LazyImage;