import React, { useEffect, useState } from "react";
import { url, Sloading, getToken } from "../base/index";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

function DiscountProduct() {
    const [num, setNum] = useState(5)
    const { user } = useSelector((state) => state.userInfo)
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    async function getDiscountProduct(Tdiscount, Bdiscount) {
        setProduct(null)
        setLoading(true)
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/user/getDiscountProduct/?Tdiscount=${Tdiscount}&Bdiscount=${Bdiscount}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            request.data && request.data.data ?
                setProduct(request.data.data) :
                setProduct(null)
            setLoading(false)
        }
        catch (error) {
            setProduct(null)
            setLoading(false)
        }
    }

    function changeNum(num, Tdiscount, Bdiscount) {
        setNum(num)
        getDiscountProduct(Tdiscount, Bdiscount)
    }

    // useEffect(() => {
    //     getDiscountProduct(100, 40)
    // }, [])

    console.log(product)
    return (
        <>
            {/* <div className="last">
                <div className="heading h4 mb-2" >Top Discount Products </div>
                <div className="d-flex mt-3" style={{ width: '250px', margin: 'auto' }}>
                    <h5 className={num === 5 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(5, 100, 40)} style={{ borderLeft: '1px solid #00000052', borderRight: '1px solid #00000052', width: '50px' }}>{`>40%`}</h5>
                    <h5 className={num === 4 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(4, 40, 30)} style={{ borderRight: '1px solid #00000052', width: '50px' }}>{`>30%`}</h5>
                    <h5 className={num === 3 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(3, 30, 20)} style={{ borderRight: '1px solid #00000052', width: '50px' }}>{`>20%`}</h5>
                    <h5 className={num === 2 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(2, 20, 10)} style={{ borderRight: '1px solid #00000052', width: '50px' }}>{`>10%`}</h5>
                    <h5 className={num === 1 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(1, 10, 1)} style={{ borderRight: '1px solid #00000052', width: '50px' }}>{`>1%`}</h5>
                </div>
                {
                    loading ?
                        <div className="d-flex pt-4 pb-4" style={{ justifyContent: 'center' }}> <Sloading width={35} height='auto' color={'red'} /> </div> :
                        user === null ?
                            <div style={{ textAlign: 'center' }}>
                                <h1 className='h6 mt-3 mb-3'>Login to see the discount product !</h1>
                                <button className='h6 primary-button ps-5 pe-5 pt-2 pb-2 pt-md-1 pb-md-1' style={{ width: 'fit-content' }} onClick={() => navigate('/user/loginUser/')}>Login</button>
                            </div> :
                            product ?
                                <div className="pt-1 pb-4">
                                    <div className="d-flex container pt-4" style={{ overflowX: 'auto', gap: '10px', scrollbarWidth: 'none' }}>
                                        {
                                            product && product.map((item, key) => {
                                                return (
                                                    <div key={key} className="discountproduct">
                                                        <img src={item.image} style={{ width: '100%' }} alt={`${item.name}`} />
                                                        <div className="discountproductdetail">
                                                            <Link to={`/product/${item.slug}/`} className=" name" style={{ fontSize: '15px' }}>{item.name}</Link>
                                                            <h4 className="" style={{ fontSize: '15px' }}>MRP : {item.mrp} Rs.</h4>
                                                            <h4 className="" style={{ fontSize: '15px' }}>Our Price : {item.price} Rs.</h4>
                                                        </div>
                                                        <div className="discount">
                                                            <h4 className="h5">{item.discount}% off</h4>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> : <div className="h5" style={{ textAlign: 'center', marginTop: '20px' }}> No Product Found!</div>
                }
            </div> */}
         <div className="heading h4 mb-1">Products ! with More Savings.</div>
            <div className='records mb-5' style={{background : 'white'}}>
        <div className='container'>
          <div className='row' >
            <div className='col-10 col-sm-6 col-lg-3 offset-1 offset-sm-0 d-flex' style={{ justifyContent: 'center', padding: '20px 0px' }}>
              <div className='single-record' style = {{width : '85%', border : '1px solid orange', borderBottom : '5px solid orange'}} data-aos='flip-left' data-aos-duration="1500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M200.6 32C205 19.5 198.5 5.8 186 1.4S159.8 3.5 155.4 16L144.7 46.2l-9.9-29.8C130.6 3.8 117-3 104.4 1.2S85 19 89.2 31.6l8.3 25-27.4-20c-10.7-7.8-25.7-5.4-33.5 5.3s-5.4 25.7 5.3 33.5L70.2 96 48 96C21.5 96 0 117.5 0 144L0 464c0 26.5 21.5 48 48 48l152.6 0c-5.4-9.4-8.6-20.3-8.6-32l0-224c0-29.9 20.5-55 48.2-62c1.8-31 17.1-58.2 40.1-76.1C271.7 104.7 256.9 96 240 96l-22.2 0 28.3-20.6c10.7-7.8 13.1-22.8 5.3-33.5s-22.8-13.1-33.5-5.3L192.5 55.1 200.6 32zM363.5 185.5L393.1 224 344 224c-13.3 0-24-10.7-24-24c0-13.1 10.8-24 24.2-24c7.6 0 14.7 3.5 19.3 9.5zM272 200c0 8.4 1.4 16.5 4.1 24l-4.1 0c-26.5 0-48 21.5-48 48l0 80 192 0 0-96 32 0 0 96 192 0 0-80c0-26.5-21.5-48-48-48l-4.1 0c2.7-7.5 4.1-15.6 4.1-24c0-39.9-32.5-72-72.2-72c-22.4 0-43.6 10.4-57.3 28.2L432 195.8l-30.5-39.6c-13.7-17.8-35-28.2-57.3-28.2c-39.7 0-72.2 32.1-72.2 72zM224 464c0 26.5 21.5 48 48 48l144 0 0-128-192 0 0 80zm224 48l144 0c26.5 0 48-21.5 48-48l0-80-192 0 0 128zm96-312c0 13.3-10.7 24-24 24l-49.1 0 29.6-38.5c4.6-5.9 11.7-9.5 19.3-9.5c13.4 0 24.2 10.9 24.2 24z"/></svg>
                {/* <h4 className='h4'>{userInfoLoading ? <Sloading color={'orange'} height={'auto'} width={'20px'} /> : `${total_kirana} +`}</h4> */}
                <h4 className='h6 mb-0'>Products with</h4>
                <h3 className="h6" onClick={()=>navigate(`product/SearchAllProduct/?dgte=40&dlte=70`)}>40% - <span className="h4" style={{color : 'red', textDecoration : 'underline'}}>70%</span> Discount</h3>
              </div>
            </div>
            <div className='col-10 col-sm-6 col-lg-3 offset-1 offset-sm-0 d-flex' style={{ justifyContent: 'center', padding: '20px 0px' }}>
              <div className='single-record' style = {{width : '85%', border : '1px solid orange', borderBottom : '5px solid orange'}} data-aos='flip-left' data-aos-duration="1500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M200.6 32C205 19.5 198.5 5.8 186 1.4S159.8 3.5 155.4 16L144.7 46.2l-9.9-29.8C130.6 3.8 117-3 104.4 1.2S85 19 89.2 31.6l8.3 25-27.4-20c-10.7-7.8-25.7-5.4-33.5 5.3s-5.4 25.7 5.3 33.5L70.2 96 48 96C21.5 96 0 117.5 0 144L0 464c0 26.5 21.5 48 48 48l152.6 0c-5.4-9.4-8.6-20.3-8.6-32l0-224c0-29.9 20.5-55 48.2-62c1.8-31 17.1-58.2 40.1-76.1C271.7 104.7 256.9 96 240 96l-22.2 0 28.3-20.6c10.7-7.8 13.1-22.8 5.3-33.5s-22.8-13.1-33.5-5.3L192.5 55.1 200.6 32zM363.5 185.5L393.1 224 344 224c-13.3 0-24-10.7-24-24c0-13.1 10.8-24 24.2-24c7.6 0 14.7 3.5 19.3 9.5zM272 200c0 8.4 1.4 16.5 4.1 24l-4.1 0c-26.5 0-48 21.5-48 48l0 80 192 0 0-96 32 0 0 96 192 0 0-80c0-26.5-21.5-48-48-48l-4.1 0c2.7-7.5 4.1-15.6 4.1-24c0-39.9-32.5-72-72.2-72c-22.4 0-43.6 10.4-57.3 28.2L432 195.8l-30.5-39.6c-13.7-17.8-35-28.2-57.3-28.2c-39.7 0-72.2 32.1-72.2 72zM224 464c0 26.5 21.5 48 48 48l144 0 0-128-192 0 0 80zm224 48l144 0c26.5 0 48-21.5 48-48l0-80-192 0 0 128zm96-312c0 13.3-10.7 24-24 24l-49.1 0 29.6-38.5c4.6-5.9 11.7-9.5 19.3-9.5c13.4 0 24.2 10.9 24.2 24z"/></svg>
                {/* <h4 className='h4'>{userInfoLoading ? <Sloading color={'orange'} height={'auto'} width={'20px'} /> : `${total_kirana} +`}</h4> */}
                <h4 className='h6 mb-0'>Products with</h4>
                <h3 className="h6" onClick={()=>navigate(`product/SearchAllProduct/?dgte=30&dlte=40`)}>30% - <span className="h4" style={{color : 'red', textDecoration : 'underline'}}>40%</span> Discount</h3>
              </div>
            </div><div className='col-10 col-sm-6 col-lg-3 offset-1 offset-sm-0 d-flex' style={{ justifyContent: 'center', padding: '20px 0px' }}>
              <div className='single-record' style = {{width : '85%', border : '1px solid orange', borderBottom : '5px solid orange'}} data-aos='flip-left' data-aos-duration="1500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M200.6 32C205 19.5 198.5 5.8 186 1.4S159.8 3.5 155.4 16L144.7 46.2l-9.9-29.8C130.6 3.8 117-3 104.4 1.2S85 19 89.2 31.6l8.3 25-27.4-20c-10.7-7.8-25.7-5.4-33.5 5.3s-5.4 25.7 5.3 33.5L70.2 96 48 96C21.5 96 0 117.5 0 144L0 464c0 26.5 21.5 48 48 48l152.6 0c-5.4-9.4-8.6-20.3-8.6-32l0-224c0-29.9 20.5-55 48.2-62c1.8-31 17.1-58.2 40.1-76.1C271.7 104.7 256.9 96 240 96l-22.2 0 28.3-20.6c10.7-7.8 13.1-22.8 5.3-33.5s-22.8-13.1-33.5-5.3L192.5 55.1 200.6 32zM363.5 185.5L393.1 224 344 224c-13.3 0-24-10.7-24-24c0-13.1 10.8-24 24.2-24c7.6 0 14.7 3.5 19.3 9.5zM272 200c0 8.4 1.4 16.5 4.1 24l-4.1 0c-26.5 0-48 21.5-48 48l0 80 192 0 0-96 32 0 0 96 192 0 0-80c0-26.5-21.5-48-48-48l-4.1 0c2.7-7.5 4.1-15.6 4.1-24c0-39.9-32.5-72-72.2-72c-22.4 0-43.6 10.4-57.3 28.2L432 195.8l-30.5-39.6c-13.7-17.8-35-28.2-57.3-28.2c-39.7 0-72.2 32.1-72.2 72zM224 464c0 26.5 21.5 48 48 48l144 0 0-128-192 0 0 80zm224 48l144 0c26.5 0 48-21.5 48-48l0-80-192 0 0 128zm96-312c0 13.3-10.7 24-24 24l-49.1 0 29.6-38.5c4.6-5.9 11.7-9.5 19.3-9.5c13.4 0 24.2 10.9 24.2 24z"/></svg>
                {/* <h4 className='h4'>{userInfoLoading ? <Sloading color={'orange'} height={'auto'} width={'20px'} /> : `${total_kirana} +`}</h4> */}
                <h4 className='h6 mb-0'>Products with</h4>
                <h3 className="h6" onClick={()=>navigate(`product/SearchAllProduct/?dgte=20&dlte=30`)}>20% - <span className="h4" style={{color : 'red', textDecoration : 'underline'}}>30%</span> Discount</h3>
              </div>
            </div><div className='col-10 col-sm-6 col-lg-3 offset-1 offset-sm-0 d-flex' style={{ justifyContent: 'center', padding: '20px 0px' }}>
              <div className='single-record' style = {{width : '85%',border : '1px solid orange', borderBottom : '5px solid orange'}} data-aos='flip-left' data-aos-duration="1500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M200.6 32C205 19.5 198.5 5.8 186 1.4S159.8 3.5 155.4 16L144.7 46.2l-9.9-29.8C130.6 3.8 117-3 104.4 1.2S85 19 89.2 31.6l8.3 25-27.4-20c-10.7-7.8-25.7-5.4-33.5 5.3s-5.4 25.7 5.3 33.5L70.2 96 48 96C21.5 96 0 117.5 0 144L0 464c0 26.5 21.5 48 48 48l152.6 0c-5.4-9.4-8.6-20.3-8.6-32l0-224c0-29.9 20.5-55 48.2-62c1.8-31 17.1-58.2 40.1-76.1C271.7 104.7 256.9 96 240 96l-22.2 0 28.3-20.6c10.7-7.8 13.1-22.8 5.3-33.5s-22.8-13.1-33.5-5.3L192.5 55.1 200.6 32zM363.5 185.5L393.1 224 344 224c-13.3 0-24-10.7-24-24c0-13.1 10.8-24 24.2-24c7.6 0 14.7 3.5 19.3 9.5zM272 200c0 8.4 1.4 16.5 4.1 24l-4.1 0c-26.5 0-48 21.5-48 48l0 80 192 0 0-96 32 0 0 96 192 0 0-80c0-26.5-21.5-48-48-48l-4.1 0c2.7-7.5 4.1-15.6 4.1-24c0-39.9-32.5-72-72.2-72c-22.4 0-43.6 10.4-57.3 28.2L432 195.8l-30.5-39.6c-13.7-17.8-35-28.2-57.3-28.2c-39.7 0-72.2 32.1-72.2 72zM224 464c0 26.5 21.5 48 48 48l144 0 0-128-192 0 0 80zm224 48l144 0c26.5 0 48-21.5 48-48l0-80-192 0 0 128zm96-312c0 13.3-10.7 24-24 24l-49.1 0 29.6-38.5c4.6-5.9 11.7-9.5 19.3-9.5c13.4 0 24.2 10.9 24.2 24z"/></svg>
                {/* <h4 className='h4'>{userInfoLoading ? <Sloading color={'orange'} height={'auto'} width={'20px'} /> : `${total_kirana} +`}</h4> */}
                <h4 className='h6 mb-0'>Products with</h4>
                <h3 className="h6" onClick={()=>navigate(`product/SearchAllProduct/?dgte=1&dlte=20`)}>1% - <span className="h4" style={{color : 'red', textDecoration : 'underline'}}>20%</span> Discount</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default DiscountProduct;