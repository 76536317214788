import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'
import { ErrorUp, SuccessUp, Sloading, url, scrollFocus, nextOnEnter, SearchIcon, CrossIcon } from '../../base/index';
import { createUser, userDefault } from '../../redux/UserSlice'
import { LazyImage, SmallLogo, Stars } from '../../components/index';
import { dropdown } from '../../redux/UserInfoSlice';
import { Helmet } from 'react-helmet'
import axios from 'axios';
import { errorName, pattern, fallbackImg } from '../../base';

function Create() {
  const user = useSelector((state) => state.userInfo.user)
  const { userError, userSuccess, userLoading, userErrorType, userAction } = useSelector((state) => state.user)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const [type, setType] = useState('password')
  const next = new URLSearchParams(location.search).get('redirect');
  const [redirect, setRedirect] = useState('/')
  const [dukans, setDukans] = useState(null)
  const [pin, setPin] = useState('')
  const [createData, setCreateData] = useState({
    'username': '',
    'email': '',
    'password': '',
    'default_dukan_id': '',
    'default_dukan_name': ''
  })
  const refrence = {
    usernameRef: useRef(null),
    emailRef: useRef(null),
    passwordRef: useRef(null),
    pinRef: useRef(null)
  }

  // function 
  function change(e) {
    setCreateData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  async function searchApniDukan() {
    setError(null)
    setDukans(null)
    if (pin.toString().length === 6) {
      try {
        const request = await axios.get(`${url}/api/user/searchApniDukan/?pin=${pin}`)
        request.data.error_message ? setError('Oops! No dukan found with this pin.') : setDukans(request.data)
      }
      catch (error) {
        setError('Oops! No dukan found with this pin.')
      }
    }
    else {
      setError('Enter 6 digit pin !')
      scrollFocus(refrence.pinRef)
    }

  }



  function selectDukan(item) {
    setCreateData((prev) => {
      return {
        ...prev,
        default_dukan_id: item.id,
        default_dukan_name: item.name
      }
    })
    setDukans(null)
  }

  function create() {
    const trimed_username = createData['username'].replace(/\s+/g, '')
    const trimed_password = createData['password'].replace(/\s+/g, '')
    const trimed_email = createData['email'].trim()
    if (trimed_username === '') {
      setError('Enter username !')
      scrollFocus(refrence.usernameRef)
    }
    else if (trimed_username[0] === '_' || trimed_username[0] === '@' || trimed_username[0] === '$') {
      setError(`Start username with letters, remove ${trimed_username[0]} from starting`)
      scrollFocus(refrence.usernameRef)
    }
    else if (!pattern.usernamePattern.test(trimed_username)) {
      setError("Use only these characters ' a-z A-Z 0-9 _ @ $ ' in username field !")
      scrollFocus(refrence.usernameRef)
    }
    else if (trimed_email === '' || !pattern.emailPattern.test(trimed_email)) {
      setError('Enter a valid email !')
      scrollFocus(refrence.emailRef)
    }
    else if (trimed_password.length < 6) {
      setError('Enter minimum 6 digit password !')
      scrollFocus(refrence.passwordRef)
    }
    else if (createData.default_dukan_id === '') {
      setError('Select apni dukan !')
      scrollFocus(refrence.pinRef)
    }
    else {
      dispatch(createUser({ 'username': createData.username, 'email': createData.email, 'password': createData.password, 'default_dukan_id': createData.default_dukan_id }))
    }
  }


  // useEffect hook
  useEffect(() => {
    dispatch(dropdown(false))
  }, [dispatch])


  useEffect(() => {
    next === null ? setRedirect('/') : setRedirect(next)
  }, [next])

  useEffect(() => {
    user !== null && userSuccess === null ? navigate('/') : <></>
  }, [user])


  useEffect(() => {
    if (userSuccess === 'user created !') {
      setSuccess('User has been successfully created !')
      const timeout = setTimeout(() => {
        window.location.href = redirect
      }, 100);
      return () => clearTimeout(timeout);
    }
    else if (userError === 'Username : User with this username already exists.') {
      setError('Username already exists! Try new username.')
      dispatch(userDefault())
    }
    else if (userError === 'Email : User with this email already exists.') {
      setError('Email already exists! Try new.')
      dispatch(userDefault())
    }
    else if (userAction === 'createUser' && userError && userErrorType === 'exception') {
      dispatch(userDefault())
      setError('Oops! can not create account, Please try again.')
    }
    else if (userAction === 'createUser' && userError && userErrorType === 'normal') {
      setError(userError)
      dispatch(userDefault())
    }
  }, [userError, userSuccess])

  console.log(dukans)
  return (
    <section className='create container' style={{ overflow: 'visible' }}>
      <Helmet>
        <title>Create Account | Mahajan Kirana</title>
      </Helmet>
      <div className='row' style={{ width: '100%', overflow: 'visible' }}>

        <div className='logoside col-12 col-md-6 d-flex mb-3' style={{ justifyContent: 'center', flexDirection: 'column', overflow: 'visible', alignItems: 'center' }}>
          <SmallLogo />
          <div className='row' style={{ marginTop: '-30px', justifyContent: 'center', overflow: 'visible' }}>
            <div className='col-3' style={{ position: 'relative', padding: '0px 0px 0px 5px' }}>
              <label className='h6' >Pin : </label>
              <input ref={refrence.pinRef} onKeyDown={(e) => nextOnEnter(e, refrence.usernameRef)} className='h6 ' style={{ flex: 1, padding: '1px' }} value={pin} onChange={(e) => setPin(e.target.value)} type='number' placeholder='Pin code' />
              <span onClick={() => searchApniDukan()}><SearchIcon style={{ position: 'absolute', bottom: '4px', right: '1px', cursor: 'pointer', fill: 'red', backgroundColor: 'white' }} /></span>
            </div>
            <div className='col-9 col-sm-6 col-md-9' style={{ overflow: 'visible', position: 'relative' }}>
              <div className='searchlist'>
                <div className='searchlistinput' style={{ width: '100%' }}>
                  <label className='h6' >Apni Dukan : </label>
                  <input className="h6" style={{ flex: 1, padding: '1px', background: 'white' }} value={createData.default_dukan_name} disabled={true} placeholder="अपनी पसंदीदा दुकान चुने " />
                  <span onClick={() => setDukans(null)} ><CrossIcon style={{ position: 'absolute', right: '9px', fill: 'gray', overflow: 'hidden', cursor: 'pointer', bottom: '3px', width: '17px' }} /></span>
                </div>
                {/* <div className="searchlistdiv" style={{ display: `${dukans === null ? 'none' : 'flex'}`, top: '44px', width: '100%' }}>
                  <div style={{ width: '100%' }}>{
                    dukans && dukans.map((item, key) => {
                      return (
                        <div className="searchlistvalue" key={key} >
                          <h1 className="h6" key={key} onClick={() => selectDukan(item)}>{item.name} </h1>
                          <hr style={{ margin: '0px' }} />
                        </div>
                      )
                    })
                  }

                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div style={dukans ? { transition: 'all .5s ease-in-out', maxWidth: '100%', opacity: '1', display: 'flex', justifyContent: 'center', margin: 'auto' } : { transition: 'all .5s ease-in-out', maxWidth: '800px', opacity: '0' }}>
            <div className="d-flex gap-3 ps-2 pe-2 mt-3" style={{ overflow: 'scroll', scrollbarWidth: 'none' }}>
              {
                dukans && dukans.map((item, index) => {
                  return (
                    <div style={{ flex: 'none', width: '220px', height: '180px', border: '1.3px solid gray', borderRadius: '10px', cursor: 'pointer' }} onClick={() => selectDukan(item)} key={index}>
                      <LazyImage src={item.banner_image} height={110} width={220} alt='dukan image' />
                      <div className="ps-1 pe-1 pb-0 d-flex" style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                        <h1 style={{ fontSize: '14px', color: 'red' }} className='truncate1'>{item.name} </h1>
                        <h1 style={{ fontSize: '11px' }} className='truncate1' >{item.address}</h1>
                        <h1 style={{ fontSize: '10px' }} className='truncate2'>{item.about}</h1>
                        <h1 style={{ fontSize: '10px', display: 'flex', gap: '5px', justifyContent: 'space-between' }}><span>Reviews : {item.total_reviews}</span> <span className="d-flex">Rating : {item.avg_rating} <Stars rating={item.avg_rating} /></span> </h1>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>


        <div className='formside col-12 col-md-6 col-lg-5 col-xl-4 offset-xl-1' >
          <div className='card create-card gap-2' data-aos="fade-down" >
            <div className='h4 text-center mb-2' style={{ textDecoration: 'underline' }}>Create Account</div>
            <label className='h5'>Username : </label>
            <input ref={refrence.usernameRef} onKeyDown={(e) => nextOnEnter(e, refrence.emailRef)} className='h6' type='text' name='username' value={createData.username} onChange={(e) => change(e)} placeholder='Enter a unique username' />
            <label className='h5'>Email : </label>
            <input ref={refrence.emailRef} onKeyDown={(e) => nextOnEnter(e, refrence.passwordRef)} className='h6' type='email' name='email' value={createData.email} onChange={(e) => change(e)} placeholder='example@gmail.com' />
            <label className='h5'>Password : </label>
            <div style={{ position: 'relative' }}>
              <input ref={refrence.passwordRef} onKeyDown={(e) => nextOnEnter(e, refrence.pinRef)} className='h6' type={`${type}`} name='password' value={createData.password} onChange={(e) => change(e)} placeholder='Enter minimum 6 digit password' />
              {
                createData.password !== '' ? type === 'password' ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ cursor: 'pointer', position: 'absolute', top: '0', left: '92%' }} onClick={() => setType('text')}>
                  <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{ cursor: 'pointer', position: 'absolute', top: '0', left: '92%' }} onClick={() => setType('password')}>
                  <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
                </svg> : <></>
              }
            </div>


                <button className='primary-button h5 pt-1 pb-1' onClick={() => create()} disabled={userLoading ? true : false}>{userLoading ? <Sloading color={'red'} width={'18px'}/> : 'Register'}</button>
            {/* <button className='primary-button h5' onClick={() => create()} disabled = {userLoading ? true : false}>Register</button> */}
            <div className='divider h6'>OR</div>
            <div className='h' style={{ textAlign: 'center', fontSize: '14px' }}>Already have account ?</div>
            <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/loginUser/?redirect=${redirect}`)} disabled={userLoading ? true : false}>Login</button>
            <div className='h' style={{ textAlign: 'center', fontSize: '14px' }}>Forgot Mahajan Kirana passowrd ?</div>
            <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/forgotPassword/?redirect=${redirect}`)} disabled={userLoading ? true : false}>Forgot password</button>
          </div>
        </div>
      </div>
      {
        error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
      }
    </section>
  )
}

export default Create