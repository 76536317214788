import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EditIcon, fallbackImg } from '../base'
import LazyImage from './LazyImage'



const SingleCategory = React.memo(({ item, dukandar = false}) => {
  const navigate = useNavigate()

  return (
    item && <div className='col-sm-6 col-lg-4' style={{ overflow: 'hidden' }}>
      <div className='singlecategory' data-aos="zoom-in-up">
        <LazyImage src={item.image} alt={item.name}/>
        {/* {
          dukandar ? <img src={item.image} alt={item.name} /> : 
          <LazyImage src={item.image} alt={item.name} />
        } */}
        <div className='singlecategorydetail'>
          <h1 className='h5 truncate1' style={{color : 'red'}}> {item.name}</h1>
          <h1 className='h5'>Total Products : {item.total_products}</h1>
          <button className='secondry-button h6 mb-3 mt-2' onClick={() => {dukandar ? navigate(`/dukandar/product/?category=${encodeURIComponent(item.slug)}`) : navigate(`/category/${item.slug}/products/`)}}>View products</button>


          {dukandar ?
            <span onClick={() => navigate(`/dukandar/category/?action=update&categoryToupdate=${encodeURIComponent(item.slug)}`)}>
              <EditIcon style={{ 'position': 'absolute', 'fill': 'red', 'top': '5px', 'right': '14px' }} />
            </span> : <></>
          }
        </div>
      </div>
    </div>
  )
})

export default SingleCategory