import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ErrorUp, nextOnEnter, scrollFocus, Sloading, SuccessUp } from '../../base/index'
import { loginUser, userDefault } from '../../redux/UserSlice'
import { SmallLogo } from '../../components/index'
import { dropdown } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'
import { errorName, pattern } from '../../base'


function Login() {

  // variable declaration
  const user = useSelector((state) => state.userInfo.user)
  const { userError, userSuccess, userLoading, userErrorType, userAction } = useSelector((state) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [type, setType] = useState('password')
  const location = useLocation();
  const next = new URLSearchParams(location.search).get('redirect');
  const [redirect, setRedirect] = useState('/')
  const [checkbox, setCheckBox] = useState(false)
  const [loginData, setLoginData] = useState({
    'username': '',
    'password': '',
    'dukandar': false
  })
  const refrence = {
    usernameRef: useRef(null),
    passwordRef: useRef(null)
  }

  function change(e) {
    setLoginData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  function login() {
    const trimed_username = loginData['username'].replace(/\s+/g, '')
    const trimed_password = loginData['password'].replace(/\s+/g, '')

    if (trimed_username === '') {
      setError('Enter username !')
      scrollFocus(refrence.usernameRef)
    }
    else if (!pattern.usernamePattern.test(trimed_username)) {
      setError("Use only these characters ' a-z A-Z 0-9 _ @ $ ' in username field !")
      scrollFocus(refrence.usernameRef)
    }
    else if (trimed_password.length < 6) {
      setError('Enter minimum 6 digit password !')
      scrollFocus(refrence.passwordRef)
    }
    else
      dispatch(loginUser({ 'username': loginData.username, 'password': loginData.password, 'dukandar': checkbox }))
  }


  // use effect hook
  useEffect(() => {
    console.log(next)
    next === null ? setRedirect('/') : setRedirect(next)
  }, [next])

  useEffect(() => {
    user !== null && userSuccess === null ? navigate('/') : <></>
  }, [user])
console.log('redirect to ', redirect)

  useEffect(() => {
    if (userSuccess === 'user logged in !') {
      setSuccess('User has been successfully logged in!')
      const timeout = setTimeout(() => {
        window.location.href = redirect
      }, 100)
      return () => clearTimeout(timeout)
    }
    else if (userAction === 'loginUser' && userError && userErrorType === 'exception') {
      dispatch(userDefault())
      setError('Oops! can not perform operation, Please try again.')
    }
    else if (userAction === 'loginUser' && userError && userErrorType === 'normal') {
      setError(userError)
      dispatch(userDefault())
    }
  }, [userError, userSuccess])

  useEffect(() => {
    dispatch(dropdown(false))
  }, [dispatch])
  // return

  console.log(userError, userErrorType, error)


  return (
    <>
      <section className='login container'>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <div className='row' style={{ width: '100%' }}>
          <div className='logoside col-12 col-md-6 d-flex' style={{ justifyContent: 'center' }}>
            <SmallLogo />
          </div>

          <div className='formside col-12 col-md-6 col-lg-5 col-xl-4 offset-xl-1' >
            <div className='card login-card gap-2'>
              <div className='h4 text-center mb-2' style={{ textDecoration: 'underline' }}>Login</div>
              <label className='h5' htmlFor="">Username : </label>
              <input ref={refrence.usernameRef} onKeyDown={(e) => nextOnEnter(e, refrence.passwordRef)} className='h6' type="text" name='username' value={loginData.username} onChange={(e) => change(e)} placeholder='Enter username' />
              <label className='h5' htmlFor="">Password : </label>
              <div style={{ position: 'relative' }}>
                <input ref={refrence.passwordRef} onKeyDown={(e) => nextOnEnter(e, refrence.usernameRef)} className='h6' type={`${type}`} name='password' value={loginData.password} onChange={(e) => change(e)} placeholder='Enter minimum 6 digit password' />
                {
                  loginData.password !== '' ? type === 'password' ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ cursor: 'pointer', position: 'absolute', top: '0', left: '92%' }} onClick={() => setType('text')}>
                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                  </svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{ cursor: 'pointer', position: 'absolute', top: '0', left: '92%' }} onClick={() => setType('password')}>
                    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
                  </svg> : <></>
                }
              </div>
              <div className='d-flex'>
                <input type='checkbox' checked={checkbox} onChange={(e) => setCheckBox(!checkbox)} style={{ width: 'fit-content', marginRight: '10px' }} />
                <h1 className=' hindi' style={{ fontSize: '14px' }}>दुकानदार खाता लॉगिन </h1>
              </div>
              <button className='primary-button h5 pt-1 pb-1' onClick={() => login()} disabled={userLoading ? true : false}>{userLoading ? <Sloading color={'red'} width={'18px'} height={'fit-content'} /> : 'Login'}</button>
              <div className='divider h6'>OR</div>
              <div className='' style={{ textAlign: 'center', fontSize: '14px' }} >Don't have Mahajan Kirana account ?</div>
              <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/createUser/?redirect=${redirect}`)} disabled={userLoading ? true : false}>Create Account</button>
              <div className='' style={{ textAlign: 'center', fontSize: '14px' }}>Forgot Mahajan Kirana passowrd ?</div>
              <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/forgotPassword/?redirect=${redirect}`)} disabled={userLoading ? true : false}>Forgot Password ?</button>
            </div>
          </div>
        </div>

        {
          error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
        }
      </section>
    </>
  )
}

export default Login

// (node:22420) [DEP_WEBPACK_DEV_SERVER_ON_AFTER_SETUP_MIDDLEWARE] DeprecationWarning: 'onAfterSetupMiddleware' option is deprecated. Please use the 'setupMiddlewares' option.
