import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { catchError, getToken, handleRequest, url } from '../base/index'
import axios from 'axios'


// creating intial state
const intialState = {
    user: null,
    userInfoError: null,
    userInfoLoading: true,

    total_item: 0,
    notification: 0,
    app_reviews: null,
    is_reviewd: true,
    avg_rating: 5,
    total_reviews: 53,
    total_kirana: 10,
    total_user: 1000,
    total_order: 1034,
    total_product: 3000,

    default_dukan_profile: null,
    default_dukan_id: null,
    default_dukan_name: null,
    is_dukandar: false,

    top_category: null,

    user_dropdown: false,
    search_dropdown: false,
    side_offcanvas: false,
}

const baseUrl = `${url}/api/user`


// creating api for the User
const userInfo = createAsyncThunk('userInfo', async (_, { rejectWithValue }) => {
    try {
        const token = getToken();
        const request = await axios.get(`${baseUrl}/userInfo/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const basicInfo = createAsyncThunk('basicInfo', async (_, { rejectWithValue }) => {
    console.log('calingg')
    try {
        const request = await axios.get(`${baseUrl}/basicInfo/`)
        console.log(request)
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        console.log(error)
        return rejectWithValue(catchError(error))
    }
})

const getApniDukan = createAsyncThunk('getApniDukan', async (dukan_id, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getApniDukan/?dukan_id=${dukan_id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})


const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: intialState,
    reducers: {
        dropdown(state, action) {
            if (action.payload.type === 'user_dropdown')
                state.user_dropdown = action.payload.value
            else if (action.payload.type === 'search_dropdown')
                state.search_dropdown = action.payload.value
            else if (action.payload.type === 'side_offcanvas')
                state.side_offcanvas = action.payload.value
            else if (action.payload === false) {
                state.user_dropdown = false
                state.search_dropdown = false
                state.side_offcanvas = false
            }


        },
        userInfoDefault(state, action) {
            state.user = null
            state.userInfoError = null
        },
        userInfoChange(state, action) {
            state.user = action.payload
        },
        notificationChange(state, action) {
            state.notification = action.payload?.data ? action.payload.data : 0
        },
        changeTotalItem(state, action) {
            action.payload.type === 'specific' ?
                state.total_item = action.payload.total_item :
                action.payload === 'inc' ?
                    state.total_item = state.total_item + 1 :
                    action.payload === 'set0' ?
                        state.total_item = 0 :
                        state.total_item = state.total_item - 1
        },
        reviewsUpdate(state, action) {
            if (action.payload.type === 'get') {
                state.app_reviews = action.payload.data
            }
            else if (action.payload.type === 'onlyreviewd') {
                state.is_reviewd = action.payload.is_reviewd
            }
            else {
                state.is_reviewd = true
                state.app_reviews === null ?
                    state.app_reviews = [action.payload.data] :
                    state.app_reviews = [action.payload.data, ...state.app_reviews]
                state.avg_rating = Number((((state.avg_rating * state.total_reviews) + Number(action.payload.rating)) / (state.total_reviews + 1)).toFixed(1))
                state.total_reviews = state.total_reviews + 1
            }
        },
        changeDefaultDukan(state, action) {
            state.default_dukan_id = action.payload.id
            state.default_dukan_name = action.payload.name
            state.default_dukan_profile = action.payload.default_dukan_profile
            state.top_category = null
        },
        setDefaultDukanProfile(state, action) {
            state.default_dukan_profile = action.payload
        },
        changeDukanReivews(state, action) {
            console.log(action.payload)
            if (action.payload.type === 'only_is_reviewd') {
                  state.default_dukan_profile.is_reviewd = action.payload.value
            }
            else {
                const { avg_rating, total_reviews, reviews } = state.default_dukan_profile
                state.default_dukan_profile.is_reviewd = action.payload.is_reviewd
                state.default_dukan_profile.avg_rating = Number((((avg_rating * total_reviews) + Number(action.payload.rating)) / (total_reviews + 1)).toFixed(1))
                state.default_dukan_profile.total_reviews = total_reviews + 1
                state.default_dukan_profile.reviews = reviews ? [action.payload.review, ...reviews] : [action.payload.review]
            }
        },
        setTopCategory(state, action) {
            state.top_category = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userInfo.pending, (state) => {
                state.userInfoLoading = true
                state.userInfoError = null
            })
            .addCase(userInfo.fulfilled, (state, action) => {
                state.userInfoLoading = false
                state.user = action.payload.user
                state.total_item = action.payload.total_item
                state.notification = action.payload.notification
                state.total_reviews = action.payload.total_reviews
                state.avg_rating = action.payload.avg_rating
                state.total_user = action.payload.total_user
                state.total_order = action.payload.total_order
                state.total_product = action.payload.total_product
                state.total_kirana = action.payload.total_dukan
                state.default_dukan_id = action.payload.default_dukan_id
                state.default_dukan_name = action.payload.default_dukan_name
                state.is_dukandar = action.payload.is_dukandar
            })
            .addCase(userInfo.rejected, (state, action) => {
                console.log(action.payload.error_message)
                state.userInfoError = action.payload.error_message
            }) // end 
            .addCase(basicInfo.pending, (state) => {
                state.userInfoLoading = true
            })
            .addCase(basicInfo.fulfilled, (state, action) => {
                console.log('basic info', action.payload)
                state.userInfoLoading = false
                state.notification = action.payload.notification
                state.total_reviews = action.payload.total_reviews
                state.avg_rating = action.payload.avg_rating
                state.total_user = action.payload.total_user
                state.total_order = action.payload.total_order
                state.total_product = action.payload.total_product
                state.total_kirana = action.payload.total_dukan
            })
            .addCase(basicInfo.rejected, (state, action) => {
                console.log('basic info', action.payload)
                state.userInfoLoading = false
            }) // end

            .addCase(getApniDukan.pending, (state) => {
                state.userInfoLoading = true
                state.userInfoError = null
            })
            .addCase(getApniDukan.fulfilled, (state, action) => {
                state.userInfoLoading = false
                // state.
            })
    }
})

export default userInfoSlice;
export { userInfo, basicInfo }
export const { userInfoDefault, userInfoChange, changeTotalItem, notificationChange, reviewsUpdate, dropdown, changeDefaultDukan, setDefaultDukanProfile, changeDukanReivews, setTopCategory } = userInfoSlice.actions
