import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dropdown } from '../../redux/UserInfoSlice'
import { getDukanUser, incDukanUserPage, dukandarDefault } from '../../redux/DukandarSlice'
import { Helmet } from 'react-helmet'
import { errorName, message, NoData, Sloading, NotAuthorized, NoConnection, Loading, NotFound404 } from '../../base/index'

function DukanUser() {
  const { user, userInfoLoading, is_dukandar } = useSelector((state) => state.userInfo)
  const { dukanUser, dukanUserPage, dukanUserFirstTime, dukandarErrorType, dukandarError, dukandarLoading, dukanUserLastPage } = useSelector((state) => state.dukandar)
  const [isMoreLoading, setIsMoreLoading] = useState(false)
  const path = window.location.pathname
  const dispatch = useDispatch()

  function load() {
    setIsMoreLoading(true)
    dispatch(getDukanUser(dukanUserPage + 1))
    dispatch(incDukanUserPage())
  }

  useEffect(() => {
    dispatch(dropdown(false))
  }, [dispatch])

  useEffect(() => {
    dukandarErrorType === 'normal' ? dispatch(dukandarDefault()) : <></>
  }, [dukandarErrorType])

  useEffect(() => {
    dukanUserFirstTime === true ? dispatch(getDukanUser(dukanUserPage)) : <></>
  }, [dukanUserFirstTime])

  useEffect(() => {
    dukandarLoading === false ? setIsMoreLoading(false) : <></>
  }, [dukandarLoading])


  console.log(dukanUser)
  return (
    <section className="dukanuser">
      <Helmet>
        <title>Dukan User</title>
      </Helmet>
      <div >
        {
          (dukanUserPage === 1 && dukandarLoading) || userInfoLoading ? <Loading /> :
            dukandarError === errorName['404Error'] ? <NotFound404 /> :
              dukandarError === errorName.internalError ? <NoConnection /> :
                dukandarError === errorName.networkError ? <NoConnection network='true' /> :
                  user === null || dukandarError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                    is_dukandar === false || dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                      dukanUser !== null ?
                        <>
                          <div className='heading h4'>Your Dukan User's</div>
                          <div className='ms-3 me-3' style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
                            <table style={{ width: '85%', maxWidth: '1200px', minWidth: '810px', margin: 'auto' }}>
                              <thead>
                                <tr>
                                  <th className='h5' style={{ color: 'green', width: '30%' }}>Username</th>
                                  <th className='h5' style={{ color: 'green', width: '50%' }}>Email</th>
                                  <th className='h5' style={{ color: 'green', width: '20%' }}>Order</th>
                                </tr>
                              </thead>
                              {
                                dukanUser && dukanUser.map((item, key) => {
                                  return (
                                    <tbody key={key}>
                                      <tr >
                                        <td className='h6'>{item.username}</td>
                                        <td className='h6'>{item.email}</td>
                                        <td className='h6'>{item.total_order}</td>
                                      </tr>
                                    </tbody>
                                  )
                                })
                              }
                            </table>
                          </div>

                          <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                            {
                              isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                dukanUserLastPage === false ?
                                  <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                            }
                          </div>
                        </> : <NoData message='Oops! No user found in your Dukan.' />

        }
      </div>
    </section>
  )
}

export default DukanUser;