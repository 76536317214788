// import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import React from "react";
import ReactLoading from 'react-loading';

function Sloading({color, height, width}) {
    return (
        <ReactLoading type='spinningBubbles' height={height} width={width} style={{display : 'flex', fill : color, height : height, width : width, justifyContent : 'center'}} />
    )
}

export default Sloading;