import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getSearchedCategory, dukandarDefault, updateDukanCategory } from '../redux/DukandarSlice';
import { useNavigate } from 'react-router-dom';
import { pattern, ErrorUp, Loading, SuccessUp, NoData } from '../base/index'
import { Button } from '@mui/material';

function UpdateDukanCategory() {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState([])
  const [display, setDisplay] = useState('none')
  const [tempImage, setTempImage] = useState(null)
  const { dukandarError, dukandarSuccess, dukandarErrorType, searchedCategory, dukanAllCategory } = useSelector((state) => state.dukandar)
  const [data, setData] = useState(null)
  const navigate = useNavigate()


  function change(e) {
    const { name, value, files } = e.target
    setData((prev) => {
      return {
        ...prev,
        [name]: files ? files.length > 0 ? files[0] : searchedCategory.image : value
      }
    })
    files ? files.length > 0 ? setTempImage(URL.createObjectURL(files[0])) : setTempImage(null) : <></>
  }


  function searchCat(item) {
    setSearch('')
    dispatch(getSearchedCategory(encodeURIComponent(item.replace(/\s+/g, "-"))))
  }

  function update() {
    console.log(data, data.image.length, data.image.length > 0)
    const trimed = data.name.replace(/\s+/g, '')
    if (trimed === '')
      setError('Enter Category Name !')
    else if (!pattern.categoryProductPattern.test(trimed))
      setError(`Use only ' a-z A-Z 0-9 | () & * '  for category name !`)
    else if (data.image && !data.image.length > 0 && data.image.size > 4 * 1024 * 1025)
      setError('Image size should be less then 4 MB')
    else if (!data.image.length > 0 &&  !data.image.type.includes('image')){
      setError('Please select only image !')
    }
    else {
      try {
        (data.image.includes('/media/'))
        dispatch(updateDukanCategory({ 'category': encodeURIComponent(searchedCategory.slug), 'data': { 'name': data.name } }))
      }
      catch (error) {
        const formData = new FormData();
        for (const key in data) {
          formData.append(key, data[key])
        }
        dispatch(updateDukanCategory({ 'category': encodeURIComponent(searchedCategory.slug), 'data': formData }))
      }

    }
  }


  useEffect(() => {
    setCategory(dukanAllCategory)
  }, [dukanAllCategory])


  useEffect(() => {
    search.length > 0 ? setDisplay('flex') : setDisplay('none')
    setCategory(
      dukanAllCategory.filter((item) => {
        return item.toLowerCase().includes(search.toLocaleLowerCase())
      })
    )
  }, [search])

console.log(dukandarError, dukandarErrorType)
  useEffect(() => {
    searchedCategory ? setData(searchedCategory) : setData(null)
    if (dukandarError !== null && dukandarErrorType === 'normal') {
      dukandarError.toLowerCase().includes('Upload a valid image.') ? setError('Upload valid image !') : setError(dukandarError)
      dispatch(dukandarDefault())
    }
    else if (dukandarSuccess !== null) {
      setSuccess(dukandarSuccess)
      dispatch(dukandarDefault())
    }
  }, [dukandarError, dukandarSuccess, searchedCategory])

  return (
    <div className='container'>
      <div className='heading h4'>Update Dukan Category</div>
      <div className='searchlist mt-4 mb-4'>
        <div className='searchlistinput'>
          <input className="h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search category here." />
          <i className="fa-solid fa-xmark h6" onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '9px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
        </div>
        {
          category.length > 0 ?
            <div className='searchlistdiv' style={{ display: display }}>
              <div style={{ width: '100%' }}>{
                category.map((item, key) => {
                  return (
                    <div className="searchlistvalue" key={key} onClick={() => searchCat(item)}>
                      <h1 className="h6" key={key}>{item} </h1>
                      <hr style={{ margin: '0px' }} />
                    </div>
                  )
                })}
              </div>
            </div> : <></>
        }
      </div>

      {
        data !== null ? <>
          <div style={{ border: '1px solid #808080a8', borderRadius: '20px', margin: 'auto', maxWidth: '335px', height: '350px', position: 'relative' }}>
            <img src={tempImage ? tempImage : data.image} alt={data.name} style={{ width: '100%', height: '70%' }} />
            <div style={{ textAlign: 'center', margin: 'auto', width: '90%' }}>
              <input type='text' className='mt-2 mb-2' name='name' value={data.name} onChange={(e) => change(e)} placeholder={data.name} />
              <input type='file' name='image' style={{ borderBottom: 'none' }} onChange={(e) => change(e)} />
              <h6 style={{ fontSize: '0.74rem', color: 'red', position: 'absolute' }}>Image size : 4 MB or less</h6>
            </div>
          </div>
          <button className='secondry-button h6 mt-4 me-5' style={{ width: 'fit-content', padding: '5px 35px' }} onClick={() => navigate(`/dukandar/product/?category=${encodeURIComponent(data.slug)}`)}>Products {data.total_products}</button>
          <button className='secondry-button h6 mt-4' style={{ width: 'fit-content', padding: '5px 35px' }} onClick={() => update()}>Update</button>
        </> : <NoData message='Oops! Category not found in your dukan.' />

      }
      {
        error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
      }
    </div>
  )
}

export default UpdateDukanCategory;