import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dropdown } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'
import { Loading, Sloading, errorName, NotAuthorized, NoConnection, message, NoData, url, getToken, NotFound404 } from '../../base'
import { dukandarDefault, getDukanCustomer, getSearchedCustomer, incDukanCustomerPage, setSearchedCustomer } from '../../redux/DukandarSlice'
import SingleCustomer from '../../components/SingleCustomer'
import { AddDukanCustomer, UpdateDukanCustomer } from '../../components'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

function DukanCustomer() {
    const [loading, setLoading] = useState(null)
    const [display, setDisplay] = useState('none')
    const [using, setUsing] = useState('number')
    const [query, setQuery] = useState('')
    const [customer, setCustomer] = useState(null)
    const [operationLoading, setOperationLoading] = useState(false) // this is for add and update operation tb loading start ho 
    const [addingData, setAddingData] = useState({ 'name': '', 'number': '', 'house_no': '', 'street_no': '', 'address': '', 'city': '' })
    const dispatch = useDispatch()
    const [index, setIndex] = useState(-1)
    const [isMoreLoading, setIsMoreLoading] = useState(false)
    const { dukandarLoading, dukandarError, dukandarErrorType, dukanCustomer, dukanCustomerPage, dukanCustomerFirstTime, dukanCustomerLastPage } = useSelector((state) => state.dukandar)
    const { userInfoLoading, is_dukandar, user } = useSelector((state) => state.userInfo)
    const path = window.location.pathname
    const location = useLocation()
    const ctu = new URLSearchParams(location.search).get('customerToupdate')
    const action = new URLSearchParams(location.search).get('action')
    const navigate = useNavigate()

    async function searchCustomer() {
        setLoading(true)
        setDisplay('flex')
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/dukandar/searchCustomer/?using=${using}&query=${query}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data && request.data.data) {
                setLoading(false)
                setCustomer(request.data.data)
                setDisplay('flex')
            }
            else if (request.data && request.data.error_message) {
                setDisplay('none')
            }
        }
        catch (error) {
            setDisplay('none')
        }
    }

    function changeUsing(using) {
        setQuery('')
        setUsing(using)
    }

    function load() {
        setIsMoreLoading(true)
        dispatch(getDukanCustomer({ 'page': dukanCustomerPage + 1 }))
        dispatch(incDukanCustomerPage())
    }

    function handleKeyDown(e) {
        if (customer && e.key === 'ArrowDown') {
            e.preventDefault()
            index < customer.length - 1 ? setIndex(prev => prev + 1) : setIndex(0)
        }
        else if (customer && e.key === 'ArrowUp') {
            e.preventDefault()
            index > 0 ? setIndex(prev => prev - 1) : setIndex(customer.length - 1)
        }
        else if (customer && (e.key === 'ArrowRight' || e.key === 'ArrowLeft')) {
            setIndex(-1)
            setDisplay('none')
        }
        else if (customer && e.key === 'Enter' && index > -1 && index < customer.length - 1) {
            navigate(`/dukandar/customer/${customer[index].id}/detailNorders/`)
        }
    }


    useEffect(() => {
        dispatch(dropdown(false))
        return () => dispatch(setSearchedCustomer(null))
    }, [dispatch])

    // useEffect(() => {
    //     action === 'add' ? setOperation('add') : action === 'update' ? setOperation('update') : setOperation('customer')
    // }, [action])

    useEffect(() => {
        action === 'update' && ctu ? dispatch(getSearchedCustomer(ctu)) : <></>
    }, [ctu])

    useEffect(() => {
        const q = query.toString()
        if (using === 'number' && q.length === 10) {
            searchCustomer()
        }
        else if (using === 'name' && q.length > 3) {
            searchCustomer()
        }
        else {
            setIndex(-1)
            setDisplay('none')
        }
    }, [query])

    useEffect(() => {
        dukanCustomerFirstTime ? dispatch(getDukanCustomer({ 'page': 1 })) : <></>
    }, [dukanCustomerFirstTime])

    useEffect(() => {
        if (dukandarLoading === false) {
            setOperationLoading(false)
            setIsMoreLoading(false)
        }
    }, [dukandarLoading])

    useEffect(()=>{
        dukandarError && dukandarErrorType === 'normal' && dispatch(dukandarDefault())
    },[dukandarError, dukandarErrorType])

    console.log(dukandarError)
    return (
        <section className='dukancustomer'>
            <Helmet>
                <title>Dukan Customers</title>
            </Helmet>

            {
                (dukanCustomerPage === 1 && dukandarLoading) || operationLoading || userInfoLoading ? <Loading /> :
                    dukandarError === errorName["404Error"] ? <NotFound404 /> :
                     dukandarError === errorName.internalError ? <NoConnection /> :
                        dukandarError === errorName.networkError ? <NoConnection network='true' /> :
                            user === null || dukandarError === errorName.authenticationError ? <NotAuthorized path={encodeURIComponent(`${path}${location.search}`)} /> :
                                is_dukandar === false || dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                                    <>
                                        <div className='addupdateoperation'>
                                            <div onClick={() => navigate(`/dukandar/customers/?action=customer`)} className={action !== 'add' && action !== 'update' ? 'active h6' : 'h6'}>Customers</div>
                                            <div onClick={() => navigate(`/dukandar/customers/?action=add`)} className={action === 'add' ? 'active h6' : 'h6'}>Add</div>
                                            <div onClick={() => navigate(`/dukandar/customers/?action=update`)} className={action === 'update' ? 'active h6' : 'h6'}>Update</div>
                                        </div>
                                        {
                                            action === 'add' ?
                                                <div className='d-block container'>
                                                    <h4 className='h4 heading'>Add Dukan Customer</h4>
                                                    <AddDukanCustomer setOperationLoading={setOperationLoading} data={addingData} setData={setAddingData} />
                                                </div> :
                                                action === 'update' ?
                                                    <div className='d-block container update'>
                                                        <h4 className='h4 heading'>Update Dukan Customer</h4>
                                                        <UpdateDukanCustomer setOperationLoading={setOperationLoading} />
                                                    </div> :
                                                    <div className='d-block container'>
                                                        <h4 className='h4 heading'>Dukan All Customers</h4>
                                                        {/* search customer */}
                                                        <div className='searchlist mt-3 mb-4 customersearch'>
                                                            <div className='searchlistinput'>
                                                                <input className="h6" onKeyDown={(e) => handleKeyDown(e)} type={using === 'number' ? 'number' : 'text'} onChange={(e) => setQuery(e.target.value)} value={query} placeholder="Search customer here." />
                                                                <svg className={using === 'number' ? 'd-block' : 'd-none'} onClick={() => changeUsing('name')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                                                                <svg className={using === 'name' ? 'd-block' : 'd-none'} onClick={() => changeUsing('number')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256l0 32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32l0 80 0 32c0 17.7 14.3 32 32 32s32-14.3 32-32l0-32c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" /></svg>
                                                            </div>
                                                            <div className="searchlistdiv" style={{ display: display }}>
                                                                <div style={{ width: '100%' }}>{
                                                                    loading === true ?
                                                                        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}><Sloading width={25} height={30} color='red' /> </div> :
                                                                        customer && customer.map((item, key) => {
                                                                            return (
                                                                                <div className="searchlistvalue" key={key} onClick={() => navigate(`/dukandar/customer/${item.id}/detailNorders/`)} style={key === index ? { backgroundColor: '#ffff0085' } : { backgroundColor: 'transparent' }}>
                                                                                    <h1 className="h6" key={key}>{item.name}</h1>
                                                                                    <hr style={{ margin: '0px' }} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {
                                                            dukanCustomer ? <>{
                                                                dukanCustomer.map((item, index) => {
                                                                    return (
                                                                        <SingleCustomer key={index} customer={item} />
                                                                    )
                                                                })}
                                                                <div className='d-flex mt-3' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                    {
                                                                        isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                                                            dukanCustomerLastPage === false ?
                                                                                <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                                                                    }
                                                                </div>
                                                            </>
                                                                : <NoData message='Oops! You do not have any customer.' />
                                                        }
                                                    </div>
                                        }




                                        {/* {
                                      moreLoading ?  
                                    } */}
                                    </>
            }
        </section>
    )
}

export default DukanCustomer